import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Customer from "./pages/Customer";
import Career from "./pages/Career";
import Services from "./pages/Services";
import ProductAtramatpage from "./pages/ProductAtramatpage";
import ProductZepfpage from "./pages/ProductZepfpage";
import ProductGelsponpage from "./pages/ProductGelsponpage";
import Events from "./pages/Events";
import ProfileCompany from "./pages/ProfileCompany";
import ContactUs from "./pages/ContactUs";
import ProductAtramatPGA75 from "./pages/ProductAtramatPGA75";
import ProductAtramatPGASG4898 from "./pages/ProductAtramatPGASG4898";
import ProductAtramatPGC25 from "./pages/ProductAtramatPGC25"; 
import ProductAtramatNylon75N from "./pages/ProductAtramatNylon75N";
import ProductAtramatchromicgut100 from "./pages/ProductAtramatchromicgut100"; 
import ProductAtramartplaingut75 from  "./pages/ProductAtramartplaingut75";
import ProductAtramartpolyCE75 from  "./pages/ProductAtramartpolyCE75";
import ProductAtramartpolyg75 from  "./pages/ProductAtramartpolyg75";
import ProductAtramartsilkr26 from  "./pages/ProductAtramartsilkr26";
import ProductAtramartsilkf17 from  "./pages/ProductAtramartsilkf17";
import ProductZepfmayoscissorscurved from  "./pages/ProductZepfmayoscissorscurved";
import ProductZepfhalstedmosquitoforcepscvd12 from  "./pages/ProductZepfhalstedmosquitoforcepscvd12";
import ProductZepfdingmanmouthgagframe from  "./pages/ProductZepfdingmanmouthgagframe";

import ProductAtramatAbsorbable from  "./pages/ProductAtramatAbsorbable";

import ProductAtramatchromic from  "./pages/ProductAtramatchromic";
import ProductAtramatpdx from  "./pages/ProductAtramatpdx";
import ProductAtramatpga from  "./pages/ProductAtramatpga";
import ProductAtramatpgarapid from  "./pages/ProductAtramatpgarapid";
import ProductAtramatpgc from  "./pages/ProductAtramatpgc";
import ProductAtramatplaingut from  "./pages/ProductAtramatplaingut";
import ProductAtramatNylon from  "./pages/ProductAtramatNylon";
import ProductAtramatPolyester from  "./pages/ProductAtramatPolyester";
import ProductAtramatPolypropelene from  "./pages/ProductAtramatPolypropelene";
import ProductAtramatSilk from  "./pages/ProductAtramatSilk";
import ProductAtramatBonewax from  "./pages/ProductAtramatBonewax";
import ProductAtramatNonAbsorbableDetail from  "./pages/ProductAtramatNonAbsorbableDetail";
import ProductAtramatAbsorbableDetail from  "./pages/ProductAtramatAbsorbableDetail";


import EventsDetail1 from  "./pages/EventsDetail1";
import EventsDetail2 from  "./pages/EventsDetail2";
import EventsDetail3 from  "./pages/EventsDetail3";
import { BrowserRouter } from "react-router-dom";

const App = () => {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/customer" element={<Customer />} />
        {/* <Route path="/services" element={<Services />} /> */}
        <Route path="/career" element={<Career />} />
        <Route path="/productatramat" element={<ProductAtramatpage />} />
        <Route path="/productzepf" element={<ProductZepfpage />} />        
        <Route path="/productgelspon" element={<ProductGelsponpage />} />                
        <Route path="/events" element={<Events />} />
        <Route path="/profilecompany" element={<ProfileCompany />} />        
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/ProductAtramatPGA75" element={<ProductAtramatPGA75 />} />
        <Route path="/ProductAtramatPGASG4898" element={<ProductAtramatPGASG4898 />} />
        <Route path="/ProductAtramatPGC25" element={<ProductAtramatPGC25 />} />  
        <Route path="/ProductAtramatNylon75N" element={<ProductAtramatNylon75N />} />      
        <Route path="/ProductAtramatchromicgut100" element={<ProductAtramatchromicgut100 />} />      
        <Route path="/ProductAtramartplaingut75" element={<ProductAtramartplaingut75 />} />      
        <Route path="/ProductAtramartpolyCE75" element={<ProductAtramartpolyCE75 />} />           
        <Route path="/ProductAtramartpolyg75" element={<ProductAtramartpolyg75 />} />  
        <Route path="/ProductAtramartsilkr26" element={<ProductAtramartsilkr26 />} />      
        <Route path="/ProductAtramartsilkf17" element={<ProductAtramartsilkf17 />} />         
        <Route path="/ProductZepfmayoscissorscurved" element={<ProductZepfmayoscissorscurved />} /> 
        <Route path="/ProductZepfhalstedmosquitoforcepscvd12" element={<ProductZepfhalstedmosquitoforcepscvd12 />} /> 
        <Route path="/ProductZepfdingmanmouthgagframe" element={<ProductZepfdingmanmouthgagframe />} /> 
        <Route path="/EventsDetail1" element={<EventsDetail1 />} /> 
        <Route path="/EventsDetail2" element={<EventsDetail2 />} />   
        <Route path="/EventsDetail3" element={<EventsDetail3 />} />   
        <Route path="/ProductAtramatchromic" element={<ProductAtramatchromic />} />
        <Route path="/ProductAtramatAbsorbable" element={<ProductAtramatAbsorbable />} />
        <Route path="/ProductAtramatpdx" element={<ProductAtramatpdx />} />
        <Route path="/ProductAtramatpga" element={<ProductAtramatpga />} />   
        <Route path="/ProductAtramatpgarapid" element={<ProductAtramatpgarapid />} />      
        <Route path="/ProductAtramatpgc" element={<ProductAtramatpgc />} />    
        <Route path="/ProductAtramatplaingut" element={<ProductAtramatplaingut />} />  
        <Route path="/ProductAtramatNylon" element={<ProductAtramatNylon />} /> 
        <Route path="/ProductAtramatPolyester" element={<ProductAtramatPolyester />} />  
        <Route path="/ProductAtramatPolypropelene" element={<ProductAtramatPolypropelene />} />  
        <Route path="/ProductAtramatSilk" element={<ProductAtramatSilk />} /> 
        <Route path="/ProductAtramatBonewax" element={<ProductAtramatBonewax />} />  
        <Route path="/ProductAtramatNonAbsorbableDetail" element={<ProductAtramatNonAbsorbableDetail />} /> 
        <Route path="/ProductAtramatAbsorbableDetail" element={<ProductAtramatAbsorbableDetail />} />         
        
        
      </Routes>
    </BrowserRouter>
  );
}

export default App;
