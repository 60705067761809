import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import cataract1 from "../assets/img/zepf/cataract/cataract1.jpg";
import cataract2 from "../assets/img/zepf/cataract/cataract2.jpg";
import cataract3 from "../assets/img/zepf/cataract/cataract3.jpg";
import cataract4 from "../assets/img/zepf/cataract/cataract4.jpg";
import cataract5 from "../assets/img/zepf/cataract/cataract5.jpg";
import cataract6 from "../assets/img/zepf/cataract/cataract6.jpg";
import { MDBIcon, MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';

const ZepfCataract = () => {
  return (
    <div className="zepfproduct d-flex justify-content-center">
      <Container>
      <Row>
               <Col className="my-2 align-items-center" data-aos="fade-up" data-aos-delay="100">
                  <h1 className="text-left fw-bold">Cataract Set</h1>
               </Col>
         </Row> 

         <Row className="row-cols-lg-2 row-cols-md-6 row-cols-sm-2 row-cols-1 g-4">        
            {/* <Col className="my-2 align-items-left"> */}
            <Col className="my-2 align-items-left py-5 px-3" data-aos="fade-up" data-aos-delay="200">
                <img src={cataract1} alt="unplash.com" className="w-20" />
            </Col>    
            <Col className="my-2 align-items-left py-5 px-3" data-aos="fade-up" data-aos-delay="200">
                <img src={cataract2} alt="unplash.com" className="w-20" />
            </Col>    
            <Col className="my-2 align-items-left py-5 px-3" data-aos="fade-up" data-aos-delay="200">
                <img src={cataract3} alt="unplash.com" className="w-20" />
            </Col> 
            <Col className="my-2 align-items-left py-5 px-3" data-aos="fade-up" data-aos-delay="200">
                <img src={cataract4} alt="unplash.com" className="w-20" />
            </Col> 
            <Col className="my-2 align-items-left py-5 px-3" data-aos="fade-up" data-aos-delay="200">
                <img src={cataract5} alt="unplash.com" className="w-20" />
            </Col> 
            <Col className="my-2 align-items-left py-5 px-3" data-aos="fade-up" data-aos-delay="200">
                <img src={cataract6} alt="unplash.com" className="w-20" />
            </Col>          
         </Row>

         <Row>
            <MDBTable>
               <MDBTableHead class="table-success">
               <tr>
                  <th scope='col'>No</th>
                  <th scope='col'>Kode</th>
                  <th scope='col'>Deskripsi</th>
               </tr>
               </MDBTableHead>
               <MDBTableBody>
               <tr>
                  <td>1</td>
                  <td>42-1208-07</td>
                  <td>WILLIAMS eye speculum 7 cm</td>
               </tr>
               <tr>
                  <td>2</td>
                  <td>43-0101-10</td>
                  <td>PRINCE muscular forceps left </td>
               </tr>
               <tr>
                  <td>3</td>
                  <td>43-3010-00</td>
                  <td>SIMCOE aspir.irrig.cann.0,9x32 mm </td>
               </tr>
               <tr>
                  <td>4</td>
                  <td>06-2920-11</td>
                  <td>Blade breaker </td>
               </tr>
               <tr>
                  <td>5</td>
                  <td>24-6500-10</td>
                  <td>BARRAQUER needleh.w'out catch</td>
               </tr>           
               <tr>
                  <td>6</td>
                  <td>42-9603-08</td>
                  <td>CLAYMAN lens implantation forceps</td>
               </tr>     
               <tr>
                  <td>7</td>
                  <td>43-1006-10</td>
                  <td>KALT capsular forceps</td>
               </tr>    
               <tr>
                  <td>8</td>
                  <td>09-1400-08</td>
                  <td>VANNAS iridectomy scissors 8cm</td>
               </tr>      
               <tr>
                  <td>9</td>
                  <td>09-1305-10</td>
                  <td>CASTROVIEJO irid.sciss.bl/bl</td>
               </tr>        
               <tr>
                  <td>10</td>
                  <td>09-1004-11</td>
                  <td>WESTCOTT tendon sciss. bl/bl</td>
               </tr>                                                                  
               <tr>
                  <td>11</td>
                  <td>09-1401-08</td>
                  <td>VANNAS iridectomy scissors 8cm</td>
               </tr>    
               <tr>
                  <td>12</td>
                  <td>42-9601-07</td>
                  <td>BINKHORST lens implantation forceps</td>
               </tr>                  
               <tr>
                  <td>13</td>
                  <td>24-6501-10</td>
                  <td>BARRAQUER needleh.w'out catch</td>
               </tr>
               <tr>
                  <td>14</td>
                  <td>14-1101-11</td>
                  <td>BACKHAUS towel forceps</td>
               </tr>
               <tr>
                  <td>15</td>
                  <td>43-1900-08</td>
                  <td>PAUFIQUE tying forceps smooth</td>
               </tr>   
               <tr>
                  <td>16</td>
                  <td>24-1200-12</td>
                  <td>DERF (WRIGHT) needleholder longit.serr</td>
               </tr>                            
               <tr>
                  <td>17</td>
                  <td>42-9400-05</td>
                  <td>BISHOP HARMAN ir.fcps.serr.0,5 mm</td>
               </tr> 
               <tr>
                  <td>18</td>
                  <td>42-5803-07</td>
                  <td>CASTROVIEJO marker</td>
               </tr> 
               <tr>
                  <td>19</td>
                  <td>42-8601-07</td>
                  <td>GILL iris forceps, tooth 0,3mm</td>
               </tr>      
               <tr>
                  <td>20</td>
                  <td>42-8805-07</td>
                  <td>BARRAQU. Col.iris fcps.,t. 0,12 mm</td>
               </tr>   
               <tr>
                  <td>21</td>
                  <td>88-1300-06</td>
                  <td>Round bowl 18/8 diam. =  60 mm</td>
               </tr>    
               <tr>
                  <td>22</td>
                  <td>43-2504-09</td>
                  <td>DESMARRES chalazion fcps.fig.1</td>
               </tr>    
               <tr>
                  <td>23</td>
                  <td>43-2506-09</td>
                  <td>DESMARRES chalazion fcps.fig.2</td>
               </tr>       
               <tr>
                  <td>24</td>
                  <td>43-2508-09</td>
                  <td>DESMARRES chalazion fcps.fig.3</td>
               </tr>                                                                    


               </MDBTableBody>
            </MDBTable>
         </Row>                

      </Container>     
    </div>
  )
}

export default ZepfCataract;
;