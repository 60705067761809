import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import bonewax from "../assets/img/atramat/bonewax/bonewax.jpg";

const Atramatbonewax = () => {
  return (
    <div className="bonewaxproduct d-flex justify-content-center">
      <Container>
       
         <Row>
               <Col className="align-items-center" data-aos="fade-up" data-aos-delay="100">
                  <h1 className="text-left fw-bold">Bonewax</h1>
               </Col>
         </Row> 

         <Row className="row-cols-lg-2 row-cols-md-6 row-cols-sm-2 row-cols-1 g-4">        
            {/* <Col className="my-2 align-items-left"> */}
            <Col className="my-2 align-items-left py-5 px-3" data-aos="fade-up" data-aos-delay="200">
                <img src={bonewax} alt="unplash.com" className="w-20" />
            </Col>    
         
         </Row>

         <Row>
               {/* <Col className="my-2 align-items-center"> */}
               <Col className="md-2 align-items-center">               
                  <h5 className="text-left text-black-50">Digunakan untuk mengontrol pendarahan dari permukaan tulang.</h5>
               </Col>
         </Row>        


         <Row>
            <Col className="mb-5 align-items-center">
               <ul class="myUL list-group mx-5 text-black-50">
                  <li>  Bertindak sebagai penghalang mekanis (sumbat), tidak memiliki aksi biokimiawi pada organisme, </li>
                  <li>  Dengan teknik pembedahan yang tepat, produk ini halus dan mudah diaplikasikan, </li>
                  <li>  Melembutkan dengan menggunakan teknik aseptik hingga konsistensi yang diinginkan, </li>
                  <li>  Untuk informasi lebih lanjut, lihat petunjuk penggunaan </li>
               </ul>
            </Col>
        </Row>             

      </Container>     
    </div>
  )
}

export default Atramatbonewax;
;