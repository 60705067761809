import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
    

const CustAreaE = () => {
  return (
    

    <div className="custarea2 d-flex align-items-center">
      <Container>
         <Row>
            <Col>
               {/* <h4 className="text-left fw-bold" data-aos="fade-left" data-aos-delay="600"> Marketing</h4>  */}
               <h4 className="text-left fw-bold">Yogyakarta, Solo</h4> 
               
               <ul class="list-group mx-3 text-black-50">
                  <li>  RSUP Sardjito, </li>
                  <li>  RSUP Soeraji, </li>
                  <li>  RS Bhayangkara, </li>
                  <li>  RS DKT Yogya, </li>
                  <li>  RS JIH, </li>
                  <li>  RS UII, </li>
                  <li>  RS Bunga Bangsa Medika, </li>
                  <li>  RS PKU Muhammadiyah Yogya, </li>
                  <li>  RS PKU Muhammadiyah Gamping, </li>
                  <li>  RS Bethesda, </li>
                  <li>  RS Ummi Khasanah, </li>
                  <li>  RS Hermina, </li>
                  <li>  RSU Pelita Husada, </li>
                  <li>  RSU Permata Husada, </li>
                  <li>  RSKB Ringroad Selatan, </li>
                  <li>  RSIA Aisyiah, </li>
                  <li>  RSPAU Hardjolukito, </li>
                  <li>  RSU Rizki Amelia, </li>
                  <li>  RSU Karima Utama, </li>
                  <li>  RS PKU Surakarta, </li>
                  <li>  RSI Cawas, </li>
                  <li>  RS PKU Muhammadiyah Wonogiri, </li>
                  <li>  RS Jati Husada, </li>
                  <li>  RS Hermina Solo </li>
               </ul>
            </Col>
            <Col>
               {/* <h4 className="text-left fw-bold" data-aos="fade-left" data-aos-delay="600"> Marketing</h4>  */}
               <h4 className="text-left fw-bold">Brebes</h4> 
               
               <ul class="list-group mx-3 text-black-50">
                  <li>  RS Permata Insani, </li>
                  <li>  RS Mutiara Bunda Brebes, </li>
                  <li>  RS Dera Assyifa </li>
               </ul>

               <h4 className="text-left fw-bold">Tegal</h4> 
               
               <ul class="list-group mx-3 text-black-50">
                  <li>  RS Adella, </li>
                  <li>  RS Al Ikhlas, </li>
                  <li>  RSIA Kasih Ibu, </li>
                  <li>  RSU Kasih Bunda, </li>
                  <li>  RS Pala Raya, </li>
                  <li>  RS Muhammadiyah Singkil </li>
               </ul>               
            </Col>   
            <Col>
               {/* <h4 className="text-left fw-bold" data-aos="fade-left" data-aos-delay="600"> Marketing</h4>  */}
               <h4 className="text-left fw-bold">Purwokerto</h4> 
               
               <ul class="list-group mx-3 text-black-50">
                  <li>  RS Siti Aminah, </li>
                  <li>  RS Islam Purwokerto, </li>
                  <li>  RSU Aghisna Medika Kroya, </li>
                  <li>  RS Siti Asiyah, </li>
                  <li>  RSIA Budhi Asih, </li>
                  <li>  RSIA Ummu Hani, </li>
                  <li>  RSU Ananda, </li>
                  <li>  RSIA Bunda Arif, </li>
                  <li>  RSU Santa Elisabeth, </li>
                  <li>  RSU Allam Medica </li>
               </ul>
            </Col>              
  
            <Col>
               {/* <h4 className="text-left fw-bold" data-aos="fade-left" data-aos-delay="600"> Marketing</h4>  */}
               <h4 className="text-left fw-bold">Surabaya</h4> 
               
               <ul class="list-group mx-3 text-black-50">
                  <li>  RS PKU Muhammadiyah Rogojampi, </li>
                  <li>  RSIA Hikmah Sawi, </li>
                  <li>  RS Citra Husada Jember, </li>
                  <li>  RS Al-Huda, </li>
                  <li>  RS Mitra Medika, </li>
                  <li>  RS Haji, </li>
                  <li>  RS Mitra Keluarga, </li>
                  <li>  RS William Booth </li>

               </ul>
            </Col>   
   
        </Row>         
      </Container>
    </div>
  )
}

export default CustAreaE;
