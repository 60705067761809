import React from 'react'
import { Container, Row, Col } from "react-bootstrap";


const HeroProfileCompany = () => {
  return (
    <div className="heroprofile">
      <Container>
         <Row>
            <Col>
                {/* <img alt="logo" src={logoemn} width="40px" />
                <span className="ms-3 fw-bold text-white fs-4 animate__animated animate__fadeInUp">PT. Endo Medica Nusantara (EMN)</span>             */}
                <h1 className="text-white text-left animate__animated animate__fadeInUp">Company Profile</h1>
             </Col>
         </Row>

       </Container>       
    </div>
  )
}

export default HeroProfileCompany;

