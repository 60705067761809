import React from 'react';
import NavProductDesc from "../components/NavProductDesc";
import HeroProductComp from "../components/HeroProductComp";
import ProdAtramatpgasg4898 from '../components/ProdAtramatpgasg4898';
import FooterComp from '../components/FooterComp';


const ProductAtramatPGASG4898 = () => {
  return( 
   <div>
    <NavProductDesc /> 
    {/* <HeroProductComp /> */}
    <ProdAtramatpgasg4898 />
    <FooterComp />
   </div>
  
  ) 
}

export default ProductAtramatPGASG4898;