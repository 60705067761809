import React from 'react'
import { Container, Row, Col } from "react-bootstrap";

const HeroProductZepf = () => {
  return (
    <div className="herozepf">
      <Container>
         <Row>
            <Col>
                {/* <h1 className="text-white text-left animate__animated animate__fadeInUp">Product</h1> */}
             </Col>
         </Row>

       </Container>        
    </div>
  )
}

export default HeroProductZepf;

