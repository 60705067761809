import React, {useState} from 'react';
import { Container, Row, Col } from "react-bootstrap";
import logoemn from "../assets/img/logo/logoemn.jpg";
// import {TiSocialFacebook,
//         TiSocialInstagram} from "react-icons/ti";
// import {RisendPlaneFill} from "react-icons/ri";        


const FooterComp = () => {
  const [year, setYear] = useState(new Date().getFullYear());


  return (
    <div className="footer pb-3 pt-4">
       <Container>
       {/* <Row className="row-cols-lg-2 row-cols-1"> */}
       <Row className="row-cols-lg-2 row-cols-md-2 row-cols-sm-2 row-cols-1 g-5">
           <Col>
             <a>
               <img alt="logo" src={logoemn} width="40px" />
               <span className="comp fw-bold text-white">PT. Endo Medica Nusantara</span>
             </a>
             {/* <p className="my-3 text-white-50" style={{ width: '350px' }}>
                 We are creating High Quality Resources and tools to Aid developers during the
                 developement of their projects
             </p> */}

             <p></p>
             
             <a href="https://www.facebook.com/emn.alkesimportir" target="_blank">
               <i class="fa-brands fa-facebook text-white fs-1 mx-lg-3 mx-2"></i>
             </a>

             <a href="https://www.instagram.com/endomedicanusantara/" target="_blank">
              <i class="fa-brands fa-instagram text-white fs-1 mx-lg-3 mx-2"></i>
             </a>

             {/* <a href="https://web.whatsapp.com"> */}
                <a href="https://wa.me/6281387037337" target="_blank">
{/* 
                <Link to='geeks/first' target='https://wa.me/6281387037337'>
                            Open First Component
                </Link> */}
                <i class="fa-brands fa-whatsapp text-white fs-1 mx-lg-3 mx-2"></i>
             </a> 
          </Col>

           <Col>
              <a>
                <span className="comp2 fw-bold text-white mb-2">Contact Us</span>
              </a>
              <p></p> 
              <a>
               <i class="fa-solid fa-house text-white"></i>
               <span className="ms-3 text-white-50">
                  Jl. Tegalan No. 1 G, Matraman,
               </span>
               <br />
               &nbsp;&nbsp;
               <span className="ms-4 text-white-50">
                 Jakarta Timur
               </span>                
               <br />
               &nbsp;&nbsp;
               <span className="ms-4 text-white-50">
                  Kode Pos. 13140 
                  {/* (Head Office) */}
               </span>                 
              </a>
              <p></p>


              {/* <a>
               <i class="fa-solid fa-house text-white"></i>
               <span className="ms-3 text-white-50">
                  Ruko Pondok Cabe Mutiara Blok D3 
               </span>  
               <br />
               &nbsp;&nbsp;
               <span className="ms-4 text-white-50">
                  Pondok Cabe Udik Pamulang, 
               </span>
               <br />
               &nbsp;&nbsp;
               <span className="ms-4 text-white-50">
                  Tangerang Selatan, Banten 
               </span>
               <br />
               &nbsp;&nbsp;
               <span className="ms-4 text-white-50">
                  Kode Pos. 15418 (Operational Office)
               </span>                 
              </a> */}
              
              <p></p>

              <a>
               <i class="fa-solid fa-phone text-white"></i>
               <span className="ms-3 text-white-50">
                 (+62)21-85911284,
               </span>
               <br />
               &nbsp;&nbsp;
               <span className="ms-4 text-white-50">
                  (+62)21-85900806  
               </span>                   
               {/* <br />
               &nbsp;&nbsp;
               <span className="ms-4 text-white-50">
                 (+62)813-8703-7337
               </span>                  */}
               <br />
               &nbsp;&nbsp;
               <span className="ms-4 text-white-50">
                  Fax: (+62)21-851 2469
               </span>                  
              </a> 
              <p></p> 
              
              <a>
              <i class="fa-solid fa-envelope text-white"></i>  
               <span className="ms-3 text-white-50">
                  endo@cbn.net.id; 
               </span>
               {/* <br />
               &nbsp;&nbsp;
               <span className="ms-4 text-white-50">
                  hrd@endomedicanusantara.com 
               </span>                         */}
              </a>                          


           </Col>           
        </Row>
        <Row>
          <Col>
            <p className="text-center text-white-50">&copy; Copyright by PT. Endo Medica Nusantara {year}, All Right Reserved.
               {/* <br />Head Office : Jl. Tegalan Raya No. 1 G, Jakarta Timur, Indonesia
               <br />Telp :(+62)21-85911284,85900806, 70305562, 0813 8077 3732,0819 0556 0321 Fax: (+62)21-851 2469
               <br />Branch Office : Villa Cinere Extention Jl. Matahari Timur Blok N6 No. 1 Pisangan – Ciputat Timur Tangerang Selatan Kode Pos 15446 Email: endo@cbn.net.id; hrd@endomedicanusantara.com; it@endomedicanusantara.com */}
            </p>
            
          </Col>
        </Row>
       </Container>         
    </div>
  )
}

export default FooterComp;



