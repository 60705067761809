import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import atramatnylon75n from "../assets/img/product/atramat/atramatnylon75n.jpg";


const ProdAtramatnylon75n = () => {
  return (
    <div className="proddesc d-flex justify-content-center" id="ProdAtramatnylon75n">
      <Container>
         <Row>
               <Col className="my-2 align-items-center">
                  <h1 className="text-center fw-bold">Atramat Nylon CE1944-75N</h1>
               </Col>
         </Row>

         <Row>          
            {/* <Col className="my-2 align-items-left"> */}
            <Col className="my-2 align-items-left py-5 px-3" data-aos="fade-up" data-aos-delay="200">
                <img src={atramatnylon75n} alt="unplash.com" className="w-20" 
                 width="850px"/>
            </Col>    
         </Row>

         <Row>
               {/* <Col className="my-2 align-items-center"> */}
               <Col className="md-2 align-items-center">               
                  <p className="text-left fw-bold">Spesification</p>
               </Col>
         </Row>

         <Row>
            <Col className="mb-5 align-items-center">
               <ul class="myUL list-group mx-3 text-black-50">
                  <li>  Monofilament, syntetic, non-absorbable suture, presented in black, blue or undyed, </li>
                  <li>  Obtained from extrusion of polyamide (6.0 and 6.6) eith a uniform cylindrical diameter, </li>
                  <li>  Tissue reaction is minimal, </li>
                  <li>  Nylon is a non absorbable material that with time becomes encapsulated by connective tissue, </li>
                  <li>  While polyamide is non absorbed, progressive hydrolysis of the polyamide in vivo may result in gradual loss over time of tensile strength, </li>
                  <li>  Normally used confronting tissue in Ophthalmic and Plastic Surgery, </li>
                  <li>  Color code : Green, </li>
                  <li>  Needle length 19 mm, Strand Length 75 cm, USP 4-0, EP Metric 1.5 </li>
               </ul>
            </Col>
        </Row>           

      </Container>     
    </div>
  )
}

export default ProdAtramatnylon75n;