import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logoemn from "../assets/img/logo/logoemn.jpg";
import { useNavigate } from 'react-router-dom';

const NavProductDesc = () => {
  const navigate = useNavigate();
    return (
        <div className="sticky-top">  
        <Navbar expand="lg" bg="dark" variant="dark">        
        {/* <Navbar expand="lg" className="bg-primary text-white bg-gradient" > */}
        {/* <Navbar expand="lg" style={{backgroundSize: "0", backgroundColor: "#A92117"}} >          */}
        {/* <Navbar expand="lg" style={{backgroundSize: "0", backgroundColor: "#057195"}} >           */}
        <Container>
        <Navbar.Brand> <img src={logoemn} width="40px"/></Navbar.Brand>

        <Navbar.Brand className="fw-bold fs-5 text-white">PT. Endo Medica Nusantara</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
         <Nav className="ms-auto text-center">
            <Nav.Link href="/#home" className="mx-2 text-white" >Home</Nav.Link>
            <Nav.Link onClick={() => navigate('/product')}  className="mx-2 text-white">Products</Nav.Link>
            {/* <Nav.Link href="product" className="mx-2 text-white">Profile</Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
      </Navbar>
      </div>
    )
  }
  
  export default NavProductDesc;