import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import career from "../assets/img/career/career.jpg";

const txtmrkt = "• Taat beribadah Kepada Tuhan YME, kerja keras, mau belajar dan bersedia mengikuti peraturan perusahaan,\n"+
                "• Pendidikan Minimal D3,";

       

const CareersDetailComp = () => {
  return (
    

    <div className="careerdetail d-flex align-items-center">
      <Container>
        {/* <Row className="row-cols-lg-2 row-cols-1"> */}
        <Row >     
            <Col className="my-5 align-items-center">
                {/* <img src={career} alt="unplash.com" 
                className="w-200"/>  */}

               <p className="text-left text-black-50">
                  Dalam rangka pengembangan usaha, kami perusahaan importir Alkes (Alat-Alat Kesehatan) 
                  yang sedang berkembang membutuhkan karyawan untuk menempati beberapa posisi sebagai berikut :
               </p>                
            </Col>                        
            {/* <Col className="align-items-center">
               <p className="my-5 text-left text-black" data-aos="fade-left" data-aos-delay="400">
                  Dalam rangka pengembangan usaha, kami perusahaan importir Alkes (Alat-Alat Kesehatan) 
                  yang sedang berkembang membutuhkan karyawan untuk menempati beberapa posisi sebagai berikut :
               </p>
            </Col> */}
        </Row> 
   
        <Row>
            <Col>
               {/* <h4 className="text-left fw-bold" data-aos="fade-left" data-aos-delay="600"> Marketing</h4>  */}
               <h2 className="text-left fw-bold"> Marketing</h2> 
               <h4 className="text-left fw-bold"> Persyaratan :</h4> 
               
               <ul class="list-group mx-3 text-black-50">
                  <li>  Taat beribadah Kepada Allah SWT, kerja keras, mau belajar dan bersedia mengikuti peraturan perusahaan, </li>
                  <li>  Pendidikan Minimal D3, </li>
                  <li>  Usia Minimal 25 Tahun dan Maximal 40 Tahun, </li>
                  <li>  Pengalaman Minimal 5 Tahun di bidang Alat Kesehatan/Farmasi Bedah Obgyn Anastesi
                        serta paham kamar bedah atau kamar operasi, 
                  </li>
                  <li>  Bersedia Ikatan Dinas 2 Tahun, </li>
                  <li>  Bersedia membuat Laporan Planning Of Action (POA) dan Daily Report, </li>
                  <li>  Lebih disukai yang paham kenal dekat dengan Divisi Bedah/Obgyn/Anestesi, </li>
                  <li>  Untuk penempatan area kerja lebih di utamakan yang berdomisili di daerah Jakarta,
                        Sumatera (Palembang, Bangka Belitung, Jambi, Bengkulu, Padang, Pekanbaru, Medan, Lampung, Batam) &
                        Sulawesi (Sulawesi Selatan, Sulawesi Barat, Sulawesi Utara, Sulawesi Tengah, Sulawesi Timur),
                        Banda Aceh, Surabaya, Solo, Bandung, Yogyakarta.
                  </li>
                  
               </ul>
            </Col>
        </Row>         

        <Row>
            <Col>
               &nbsp;&nbsp;
               {/* <h4 className="text-left fw-bold" data-aos="fade-left" data-aos-delay="600"> Marketing</h4>  */}
               <h2 className="text-left fw-bold"> IT Web Programmer / Full Stack Developer</h2> 
               <h4 className="text-left fw-bold"> Persyaratan :</h4> 
               
               <ul class="list-group mx-3 text-black-50">
                  <li>  Taat beribadah Kepada Allah SWT, kerja keras, mau belajar dan bersedia mengikuti peraturan perusahaan, </li>
                  <li>  Pendidikan Minimal D3, </li>
                  <li>  Usia Minimal 25 Tahun dan Maximal 40 Tahun, </li>
                  <li>  Pengalaman Minimal 2 Tahun, </li>
                  <li>  Menguasai Database MySQL, Firebird, MongoDB, </li>
                  <li>  Menguasai Web Application Programming, Web Service, dan API. Diutamakan yang menguasai JavaScript, NodeJs, ReactJs, VueJs, Bootstrap, </li>
                  <li>  Diutamakan yang berdomisili di Tangerang Selatan, Depok dan sekitarnya </li>
               </ul>
            </Col>
        </Row> 
    
        <Row>
            <Col>
               &nbsp;&nbsp;
               {/* <h4 className="text-left fw-bold" data-aos="fade-left" data-aos-delay="600"> Marketing</h4>  */}
               <h2 className="text-left fw-bold"> HRD - General Affair (GA)</h2> 
               <h4 className="text-left fw-bold"> Persyaratan :</h4> 
               
               <ul class="list-group mx-3 text-black-50">
                  <li>  Taat beribadah Kepada Allah SWT, kerja keras, mau belajar dan bersedia mengikuti peraturan perusahaan, </li>
                  <li>  Laki-laki (Usia maksimum 30 tahun), </li>
                  <li>  Pendidikan minimal D3, </li>
                  <li>  Wajib pengalaman sebagai HR & GA Minimal 2 tahun, </li>
                  <li>  Wajib memiliki SIM A & C, </li>
                  <li>  Komunikatif, Good Attitude dan tahan banting, </li>
                  <li>  Tegas, teliti, dan bertanggung jawab, </li>
                  <li>  Dapat bekerja multitasking, </li>
                  <li>  Diutamakan berdomisili di Tangsel, Depok, dan sekitarnya </li>
               </ul>
            </Col>
        </Row> 

        <Row>
            <Col>
               &nbsp;&nbsp;
               {/* <h4 className="text-left fw-bold" data-aos="fade-left" data-aos-delay="600"> Marketing</h4>  */}
               <h2 className="text-left fw-bold"> Admin + Kurir</h2> 
               <h4 className="text-left fw-bold"> Persyaratan :</h4> 
               
               <ul class="list-group mx-3 text-black-50">
                  <li>  Taat beribadah Kepada Allah SWT, kerja keras, mau belajar dan bersedia mengikuti peraturan perusahaan, </li>
                  <li>  Laki-laki (Usia maksimum 30 tahun), </li>
                  <li>  Pendidikan minimal D3, </li>
                  <li>  Wajib mampu mengoperasikan komputer (Ms.Word, Ms.Excel, Ms.Power Point), </li>
                  <li>  Wajib dapat mengoperasikan rumus-rumus excel (Pivot table, VLookup, dll), </li>
                  <li>  Berpengalaman dalam tugas administrasi dan pemasukan data (data entry), </li>
                  <li>  Memiliki kendaraan pribadi, Surat Ijin Mengemudi (SIM), </li>
                  <li>  Memiliki ponsel sebagai alat komunikasi dan nomor telepon yang aktif, </li>
                  <li>  Memiliki keterampilan untuk berkomunikasi dengan baik, </li>
                  <li>  Memahami rute perjalanan daerah setempat untuk keperluan mengantar barang, </li>
                  <li>  Dalam kondisi sehat, baik jasmani maupun rohani serta pekerja keras dan rajin, </li>
                  <li>  Memiliki Surat Keterangan Catatan Kepolisian (SKCK), dapat menyusul apabila belum ada,, </li>
                  <li>  Diutamakan yang berdomisili wilayah Kecamatan Bukit Intan, Kota Pangkal Pinang </li>
               </ul>
            </Col>
        </Row> 

        <Row className="mb-5"> 
            <Col>
               &nbsp;
               {/* <h4 className="text-left fw-bold" data-aos="fade-left" data-aos-delay="600"> Marketing</h4>  */}
               <h5 className="text-left fw-bold text-black"> Kirimkan CV yang dilengkapi dengan pas foto diri Anda yang terbaru via email ke : </h5> 


               <ul class="list-unstyled">
                  <li> 
                     <i class="fa-solid fa-envelope"></i>
                     <span className="ms-2  text-black-50">
                       hrd@endomedicanusantara.com; recruit@endomedicanusantara.com; hrdendomedicanusantara@gmail.com
                     </span>
                  </li>
               </ul>   
                         
              <h1 className="text-black"> Cantumkan Subject email sesuai dengan posisi yang ditawarkan </h1>               

            </Col>
        </Row>                 
      </Container>
    </div>
  )
}

export default CareersDetailComp;
