import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Atramatpgc from './Atramatpgc';


const ProductDetailAtramatPgcComp = () => {
  return (
       <div className="productlist d-flex justify-content-left">
      <Container>
         <Row>
               <Col>
                  <Atramatpgc />
               </Col>                                    
          </Row>
       </Container>     
     </div>
  )
}

export default ProductDetailAtramatPgcComp;