import React from 'react'
import { Container, Row, Col } from "react-bootstrap";

const HeroEventsComp = () => {
  return (
    <div className="heroevents">
      <Container>
         <Row>
            <Col>
               <h1 className="text-white text-left animate__animated animate__fadeInUp">News & Events</h1> 
             </Col>
         </Row>
       </Container>      
    </div>
  )
}

export default HeroEventsComp;
