import React from 'react';
import NavProductDesc from "../components/NavProductDesc";
import ProdAtramartpolyg75 from '../components/ProdAtramartpolyg75';
import FooterComp from '../components/FooterComp';


const ProductAtramartpolyg75 = () => {
  return( 
   <div>
    <NavProductDesc /> 
    {/* <HeroProductComp /> */}
    <ProdAtramartpolyg75 />
    <FooterComp />
   </div>
  
  ) 
}

export default ProductAtramartpolyg75;