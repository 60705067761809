import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import NavDropdown from 'react-bootstrap/NavDropdown';
import logoemn from "../assets/img/logo/logoemn.jpg";
import { useNavigate } from 'react-router-dom';
import { useState } from "react";



const NavMainComp = () => {
    const navigate = useNavigate();

    const [activeLink, setActiveLink] = useState("contactus");

    const handleLinkClick = (link) => {
      setActiveLink(link);
    };


     return (
        <div className="navemn sticky-top">  
        <Navbar expand="lg" bg="dark" variant="dark">        
        {/* <Navbar expand="lg" className="bg-primary text-white bg-gradient" > */}
        {/* <Navbar expand="lg" style={{backgroundSize: "0", backgroundColor: "#A92117"}} >          */}
        {/* <Navbar expand="lg" style={{backgroundSize: "0", backgroundColor: "#057195"}} >           */}
        <Container>
        <Navbar.Brand> 
             <a>
               <img src={logoemn}/>
               <span className="navprod fw-bold text-white">PT. Endo Medica Nusantara</span>
             </a>          
        </Navbar.Brand>

        {/* <Navbar.Brand href="#home" className="title fw-bold text-white">PT. Endo Medica Nusantara</Navbar.Brand> */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto text-center">
              {/* <ul>
                <li href="/#home" className="navtitle mx-2 text-white" >Home</li>
                <li href="/#home" className="navtitle mx-2 text-white" >Profile</li>

              </ul> */}
            {/* <Nav.Link href="/#home" className="navtitle mx-2 text-white">Home</Nav.Link>               
            <Nav.Link href="/#profile" className="navtitle mx-2 text-white">Profile</Nav.Link> 
            <Nav.Link href="/#principles" className="navtitle mx-2 text-white">Principals</Nav.Link> 
            <Nav.Link href="/#services" className="navtitle mx-2 text-white">Services</Nav.Link> */}

            <Nav.Link href="/#home" className={activeLink === "home" ? "active" : ""}
                      onClick={() => handleLinkClick("profile")}>Home</Nav.Link>            
            {/* <Nav.Link href="/#profile" className={activeLink === "profile" ? "active" : ""}
                      onClick={() => handleLinkClick("profile")}>Profile</Nav.Link>
            <Nav.Link href="/#principles" className={activeLink === "principles" ? "active" : ""}
                      onClick={() => handleLinkClick("principles")}>Principals</Nav.Link>   
   
            <Nav.Link href="/#services" className={activeLink === "services" ? "active" : ""}
                      onClick={() => handleLinkClick("services")}>Services</Nav.Link>  */}


            <NavDropdown 
                    // className="text-primary background-color= red"
                    // style={{backgroundColor: "#FF0000"}}

                    title={
                      // <span className="text-white my-auto">Products</span>
                      <span className={activeLink === "products" ? "active" : ""} onClick={() => handleLinkClick("products")} >Products</span>
                  }

                  // id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item className="navdrop text-dark" onClick={() => navigate('/productatramat')}>Atramat</NavDropdown.Item>
                    <NavDropdown.Item className="navdrop text-dark" onClick={() => navigate('/productzepf')}>Zepf</NavDropdown.Item>
                    <NavDropdown.Item className="navdrop text-dark" onClick={() => navigate('/productgelspon')}>Gelspon</NavDropdown.Item>
            </NavDropdown> 
           
            <Nav.Link href="/events" className={activeLink === "events" ? "active" : ""}
                onClick={() => handleLinkClick("events")}>News & Events</Nav.Link>  
            <Nav.Link href="/career" className={activeLink === "career" ? "active" : ""}
                onClick={() => handleLinkClick("career")}>Careers</Nav.Link>  
            <Nav.Link href="/contactus" className={activeLink === "contactus" ? "active" : ""}
                onClick={() => handleLinkClick("contactus")}>Contact Us</Nav.Link>  


{/* 
            <Nav.Link onClick={() => navigate('/events')} className="navtitle mx-2 text-white">News & Events</Nav.Link>            
            <Nav.Link onClick={() => navigate('/career')} className="navtitle mx-2 text-white">Careers</Nav.Link>
            <Nav.Link onClick={() => navigate('/contactus')} className="navtitle mx-2 text-white">Contact Us</Nav.Link> */}

          </Nav>
        </Navbar.Collapse>
      </Container>
      </Navbar>
      </div>
    )
  }
  
  export default NavMainComp;