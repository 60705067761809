import React from 'react';
import NavProductAtramatComp from "../components/NavProductAtramatComp";
import HeroProductAtramat from "../components/HeroProductAtramat";
import AtramatAbsorbablesutures from '../components/AtramatAbsorbablesutures';
import FooterComp from '../components/FooterComp';
import NavMainComp from "../components/NavMainComp";

const ProductAtramatAbsorbable = () => {
  return( 
   <div>
    <NavProductAtramatComp /> 
    {/* <NavMainComp />      */}
    <HeroProductAtramat />
    <AtramatAbsorbablesutures />
    <FooterComp />
   </div>
  
  ) 
}

export default ProductAtramatAbsorbable;