import React from 'react';
import NavProductDesc from "../components/NavProductDesc";
import HeroProductComp from "../components/HeroProductComp";
import ProdAtramatchromicgut100 from '../components/ProdAtramatchromicgut100';
import FooterComp from '../components/FooterComp';


const ProductAtramatchromicgut100 = () => {
  return( 
   <div>
    <NavProductDesc /> 
    {/* <HeroProductComp /> */}
    <ProdAtramatchromicgut100 />
    <FooterComp />
   </div>
  
  ) 
}

export default ProductAtramatchromicgut100;