import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Carousel, CarouselItem  } from "react-bootstrap";
import PrincipalCard from './PrincipalCard';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from "react-bootstrap/Button";
import emnvideo from "../assets/img/principles/AtramatZepf.mp4";
import atramatvideo from "../assets/img/principles/Atramat.mp4";
import zepfvideo from "../assets/img/principles/Zepf.mp4";
import CardAtramat from './CardAtramat';
import CardZepf from './CardZepf';
import CardGelspon from './CardGelspon';
import vision from "../assets/img/profile/vision.jpg";

const PrinciplesComp = () => {
  return (
    // <div className="principles  min-vh-100 d-flex align-items-center" id="principles">
    <div className="principles d-flex align-items-center" id="principles">
      <Container className="text-center">
        <Row className="mb-1">
            <Col>
               {/* <h1 className="text-center fw-bold"  data-aos="fade-up">Principals</h1> */}
               <h1 className="text-center fw-bold">Principals</h1>
               {/* <p className="text-center text-black-50"  data-aos="fade-up" data-aos-delay="200"> */}
               <p className="text-center text-black-50">
                  Kami adalah mitra terpercaya yang telah mendapat dukungan dari beberapa brand principal alat-alat kesehatan resmi dunia.
               </p>
            </Col>
        </Row>

        <Row className='my-2'>
            <Col data-aos="fade-right" data-aos-delay="100">
                <iframe 
                    className= "officemap1"
                    // allowTransparency="true" 
                    
                    width="560"
                    height="315"
                    frameborder="0" 
                    scrolling="no"
                    marginwidth="0" 
                    src="https://www.youtube-nocookie.com/embed/c-F2Sk1POkM?rel=0"
                    // src={emnvideo}
                    controls controlsList="nodownload"
                    autoplay="false"
                    >
                </iframe>                    
            </Col> 
 
            <Col data-aos="fade-right" data-aos-delay="100">
                <iframe 
                    className= "officemap1"
                    // allowTransparency="true" 
                    
                    width="560"
                    height="315"
                    frameborder="0" 
                    scrolling="no"
                    marginwidth="0" 
                    src="https://www.youtube-nocookie.com/embed/mETjoY5sPpA?rel=0"
                    // src="https://www.youtube-nocookie.com/embed/mETjoY5sPpA?rel=0 fs=0&modestbranding=0"
                    // src={emnvideo}
                    controls controlsList="nodownload"
                    autoplay="false"
                    >
                </iframe>                    
            </Col>              
       </Row>               

        {/* <Row className='my-2 align-items-center'>
          <Col>
            <Carousel>
              <CarouselItem>            
                  <iframe className= "officemap1 my-5"
                      
                      width="560"
                      height="315"
                      frameborder="0" 
                      scrolling="no" 
                      marginheight="0" 
                      marginwidth="0" 
                      src={emnvideo}
                      autoplay="false"
                  >
                  </iframe> 
              </CarouselItem>
            </Carousel>                  
                                 
          </Col> 
        </Row>     */}

     

    
        <Row className='my-5 align-items-center'>
          <div>
            <Carousel indicators={false} controls={false}>
             <CarouselItem className="principalcarousel">  
                   {/* <Row className='card1 my-5 align-items-center'> */}
                   {/* <Row className='my-5 align-items-center'>  */}

                   <Row className="row-cols-lg-3 row-cols-md-3 row-cols-sm-2 row-cols-1 g-4">
                        <Col>
                            <CardAtramat />  
                        </Col>   
                        <Col>
                            <CardZepf />  
                        </Col> 
                        <Col>
                            <CardGelspon />  
                        </Col>                         
                        
                    </Row>                                         
             </CarouselItem>
            </Carousel>
          </div>  
        </Row>

      </Container>

  

    </div>
  )
}

export default PrinciplesComp;
