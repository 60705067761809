import React from 'react';
import NavProductDesc from "../components/NavProductDesc";
import ProdAtramartpolyCE75 from '../components/ProdAtramartpolyCE75';
import FooterComp from '../components/FooterComp';


const ProductAtramartpolyCE75 = () => {
  return( 
   <div>
    <NavProductDesc /> 
    {/* <HeroProductComp /> */}
    <ProdAtramartpolyCE75 />
    <FooterComp />
   </div>
  
  ) 
}

export default ProductAtramartpolyCE75;