import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
    

const CustAreaF = () => {
  return (
    

    <div className="custarea2 d-flex align-items-center">
      <Container>
         <Row>
            <Col>
               {/* <h4 className="text-left fw-bold" data-aos="fade-left" data-aos-delay="600"> Marketing</h4>  */}
               <h4 className="text-left fw-bold">Palembang</h4> 
               
               <ul class="list-group mx-3 text-black-50">
                  <li>  RSI Siti Khadijah, </li>
                  <li>  RS Siloam, </li>
                  <li>  RS Bhayangkara, </li>
                  <li>  RSUD Empat Lawang, </li>
                  <li>  RS Charitas Karya Asih, </li>
                  <li>  RS Pertamina Plaju, </li>
                  <li>  RSIA Azahrah, </li>
                  <li>  RS Pertamina Prabumulih, </li>
                  <li>  RS Pusri, </li>
                  <li>  RS Pelabuhan, </li>
                  <li>  RSI Ar Rasyid, </li>
                  <li>  RSUD Lahat, </li>
                  <li>  RSUD Muara Dua, </li>
                  <li>  RS Muhammadiyah, </li>
                  <li>  RS Myria, </li>
                  <li>  RSUD M Yunus, </li>
                  <li>  RSUD M Hoesin </li>
               </ul>
            </Col>
            <Col>
               {/* <h4 className="text-left fw-bold" data-aos="fade-left" data-aos-delay="600"> Marketing</h4>  */}
               <h4 className="text-left fw-bold">Bandar Lampung</h4> 
               
               <ul class="list-group mx-3 text-black-50">
                  <li>  RS Advent, </li>
                  <li>  RS Graha Husada, </li>
                  <li>  RS Mardi Waluyo, </li>
                  <li>  RS Kartini, </li>
                  <li>  RS Hermina, </li>
                  <li>  RS Imanuel, </li>
                  <li>  RS Bhayangkara </li>
               </ul>
               <h4 className="text-left fw-bold">Bengkulu</h4> 
               
               <ul class="list-group mx-3 text-black-50">
                  <li>  RS Bhayangkara, </li>
                  <li>  RS Ummi Mandiri, </li>
                  <li>  RS Tiara Sella </li>
               </ul>          

            </Col>   
            <Col>
               {/* <h4 className="text-left fw-bold" data-aos="fade-left" data-aos-delay="600"> Marketing</h4>  */}
               <h4 className="text-left fw-bold">Bangka Belitung</h4> 
               
               <ul class="list-group mx-3 text-black-50">
                  <li>  RS Siloam, </li>
                  <li>  RS Bhakti Timah, </li>
                  <li>  Klinik Salam Sarma </li>
                  <li>  RSUD Depati Hamzah </li>
                  <li>  RSIA Muhaya </li>
                  <li>  RS Medika Stania </li>
                  <li>  RSUD Sungai Liat </li>
                  <li>  RS Arsani </li>
                  <li>  RS Katolik Bhakti Wara </li>
               </ul>              

               <h4 className="text-left fw-bold">Jambi</h4> 
               
               <ul class="list-group mx-3 text-black-50">
                  <li>  RS Rapha Theresia, </li>
                  <li>  RS DKT Bratanata, </li>
                  <li>  RS Theresia </li>
               </ul> 
            </Col>              
            <Col>
               {/* <h4 className="text-left fw-bold" data-aos="fade-left" data-aos-delay="600"> Marketing</h4>  */}
               <h4 className="text-left fw-bold">Medan</h4> 
               
               <ul class="list-group mx-3 text-black-50">
                  <li>  RS Sarah, </li>
                  <li>  RSI Malahayati, </li>
                  <li>  RS Bandung, </li>
                  <li>  RS Mitra Sejati, </li>
                  <li>  RS Pertamina, </li>
                  <li>  RS Bangkatan, </li>
                  <li>  RS Artha Medika, </li>
                  <li>  RS Efarina Etaham, </li>
                  <li>  RS Mutiara Cabang, </li>
                  <li>  RS Hermina </li>
               </ul>

               <h4 className="text-left fw-bold">Padang</h4> 
               
               <ul class="list-group mx-3 text-black-50">
                  <li>  RS Semen Padang, </li>
                  <li>  RS Siti Hawa, </li>
                  <li>  RS Hermina, </li>
                  <li>  RSI Ibnu Sina, </li>
                  <li>  RS Yos Sudarso </li>
               </ul>               
            </Col>   
            <Col>
               {/* <h4 className="text-left fw-bold" data-aos="fade-left" data-aos-delay="600"> Marketing</h4>  */}
               <h4 className="text-left fw-bold">Pekanbaru</h4> 
               
               <ul class="list-group mx-3 text-black-50">
                  <li>  RS Pekanbaru Medical Center, </li>
                  <li>  RS Efarina, </li>
                  <li>  RS Permata Hati, </li>
                  <li>  RSIA Anisa, </li>
                  <li>  RS Hermina, </li>
                  <li>  RS Syafira </li>
               </ul>

               <h4 className="text-left fw-bold">Aceh</h4> 
               
               <ul class="list-group mx-3 text-black-50">
                  <li>  RS Kesdam Iskandar Muda, </li>
                  <li>  RS PIM, </li>
                  <li>  RS Bunga Melati Lhokseumawe, </li>
                  <li>  RS Avicenna, </li>
                  <li>  RSU Metro Medikal Center, </li>
                  <li>  RSUD Datu Beru Takengon </li>
               </ul>
            </Col>   
   
        </Row>         
      </Container>
    </div>
  )
}

export default CustAreaF;
