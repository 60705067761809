import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import atramatpgc25 from "../assets/img/product/atramat/atramatpgc25.jpg";


const ProdAtramatpgc25 = () => {
  return (
    <div className="proddesc d-flex justify-content-center" id="ProductAtramatPGC25">
      <Container>
         <Row>
               <Col className="my-2 align-items-center">
                  <h1 className="text-center fw-bold">Atramat PGC25 PE1904-B</h1>
               </Col>
         </Row>

         <Row>          
            {/* <Col className="my-2 align-items-left"> */}
            <Col className="my-2 align-items-left py-5 px-3" data-aos="fade-up" data-aos-delay="200">
                <img src={atramatpgc25} alt="unplash.com" className="w-20" 
                 width="850px"/>
            </Col>    
         </Row>

         <Row>
               {/* <Col className="my-2 align-items-center"> */}
               <Col className="md-2 align-items-center">               
                  <p className="text-left fw-bold">Spesification</p>
               </Col>
         </Row>

         <Row>
            <Col className="mb-5 align-items-center">
               <ul class="myUL list-group mx-3 text-black-50">
                  <li>  Synthetic, absorbable, multifilament braided suture, presented in violet or undyed, </li>
                  <li>  Made of a copolymer of glycolid and epsilon caprolactone, </li>
                  <li>  The tissue reactivity is minimal, </li>
                  <li>  Absorption occurs through progressive hydrolytic action, completed between 90 and 120 days, </li>
                  <li>  The material retains approximately 60% of its initial tensile strength by the first weeks, </li>
                  <li>  Frequently used in soft tissue coaptation, </li>
                  <li>  Color code : Light Orange, </li>
                  <li>  Needle length 19 mm, Strand Length 70 cm, USP 4-0, EP Metric 1.5 </li>
               </ul>
            </Col>
        </Row>           

      </Container>     
    </div>
  )
}

export default ProdAtramatpgc25;