import React from 'react';
import NavProductAtramatComp from "../components/NavProductAtramatComp";
import HeroProductAtramat from "../components/HeroProductAtramat";
import HeroProductAtramatPolypropelene from "../components/HeroProductAtramatPolypropelene";
import ProductDetailAtramatPolypropeleneComp from '../components/ProductDetailAtramatPolypropeleneComp';
import FooterComp from '../components/FooterComp';
import NavMainComp from "../components/NavMainComp";

const ProductAtramatPolypropelene = () => {
  return( 
   <div>
    <NavProductAtramatComp /> 
    {/* <NavMainComp />      */}
    <HeroProductAtramat />
    <ProductDetailAtramatPolypropeleneComp /> 
    <FooterComp />
   </div>
  
  ) 
}

export default ProductAtramatPolypropelene;