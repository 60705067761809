import React from 'react';
import NavProductDesc from "../components/NavProductDesc";
import HeroProductComp from "../components/HeroProductComp";
import ProdAtramatpgc25 from '../components/ProdAtramatpgc25';
import FooterComp from '../components/FooterComp';


const ProductAtramatPGC25 = () => {
  return( 
   <div>
    <NavProductDesc /> 
    {/* <HeroProductComp /> */}
    <ProdAtramatpgc25 />
    <FooterComp />
   </div>
  
  ) 
}

export default ProductAtramatPGC25;