import React from 'react';
import NavProductAtramatComp from "../components/NavProductAtramatComp";
import HeroProductAtramatPGC from "../components/HeroProductAtramatPGC";
import HeroProductAtramat from "../components/HeroProductAtramat";
import ProductDetailAtramatPgcComp from '../components/ProductDetailAtramatPgcComp';
import FooterComp from '../components/FooterComp';
import NavMainComp from "../components/NavMainComp";

const ProductAtramatpgc = () => {
  return( 
   <div>
    <NavProductAtramatComp /> 
    {/* <NavMainComp />      */}
    <HeroProductAtramat />
    <ProductDetailAtramatPgcComp />
    <FooterComp />
   </div>
  
  ) 
}

export default ProductAtramatpgc;