import React from 'react';
import NavProductGelsponComp from "../components/NavProductGelsponComp";
import HeroProductGelspon from "../components/HeroProductGelspon";
import GelsponComp from '../components/GelsponComp';
import FooterComp from '../components/FooterComp';
import NavMainComp from "../components/NavMainComp";

const ProductGelsponpage = () => {
  return( 
   <div>
    <NavProductGelsponComp /> 
    {/* <NavMainComp />  */}
    <HeroProductGelspon />
    <GelsponComp />
    <FooterComp />
   </div>
  
  ) 
}

export default ProductGelsponpage;