import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Atramatnylon from './Atramatnylon';


const ProductDetailAtramatNylonComp = () => {
  return (
       <div className="productlist d-flex justify-content-left">
      <Container>
         <Row>
               <Col>
                  <Atramatnylon />
               </Col>                                    
          </Row>
       </Container>     
     </div>
  )
}

export default ProductDetailAtramatNylonComp;