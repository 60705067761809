import React from 'react';
import NavProductDesc from "../components/NavProductDesc";
import HeroProductComp from "../components/HeroProductComp";
import ProdAtramatpga75 from '../components/ProdAtramatpga75';
import FooterComp from '../components/FooterComp';


const ProductAtramatPGA75 = () => {
  return( 
   <div>
    <NavProductDesc /> 
    {/* <HeroProductComp /> */}
    <ProdAtramatpga75 />
    <FooterComp />
   </div>
  
  ) 
}

export default ProductAtramatPGA75;