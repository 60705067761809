import React from 'react';
import NavProductDesc from "../components/NavProductDesc";
import HeroProductComp from "../components/HeroProductComp";
import ProdAtramatnylon75n from '../components/ProdAtramatnylon75n';
import FooterComp from '../components/FooterComp';


const ProductAtramatNylon75N = () => {
  return( 
   <div>
    <NavProductDesc /> 
    {/* <HeroProductComp /> */}
    <ProdAtramatnylon75n />
    <FooterComp />
   </div>
  
  ) 
}

export default ProductAtramatNylon75N;