import React from 'react';
import NavEventsComp from "../components/NavEventsComp";
import NavMainEventComp from "../components/NavMainEventComp";
import HeroEventsComp from '../components/HeroEventsComp';
import EventsCompDetail3 from '../components/EventsCompDetail3';
import FooterComp from '../components/FooterComp';


const EventsDetail3 = () => {
  return( 
   <div>
    {/* <NavEventsComp />  */}
    <NavMainEventComp />     
    <HeroEventsComp />     
    <EventsCompDetail3 />
    <FooterComp />
   </div>
  
  ) 
}

export default EventsDetail3;