import React from 'react';
import NavProductAtramatComp from "../components/NavProductAtramatComp";
import HeroProductAtramat from "../components/HeroProductAtramat";
import NavProductAtramatMenu from "../components/NavProductAtramatMenu";
import HeroProductAtramatBonewax from "../components/HeroProductAtramatBonewax";
import ProductDetailAtramatBonewax from '../components/ProductDetailAtramatBonewax';
import FooterComp from '../components/FooterComp';
import NavMainComp from "../components/NavMainComp";

const ProductAtramatBonewax = () => {
  return( 
   <div>
    <NavProductAtramatComp /> 
    {/* <NavMainComp />      */}
    <HeroProductAtramat /> 
    {/* <NavProductAtramatMenu />    */}
    <ProductDetailAtramatBonewax /> 
    <FooterComp />
   </div>
  
  ) 
}

export default ProductAtramatBonewax;