import React from 'react';
import NavProductAtramatComp from "../components/NavProductAtramatComp";
import HeroProductAtramatPlainGut from "../components/HeroProductAtramatPlainGut";
import HeroProductAtramat from "../components/HeroProductAtramat";
import ProductDetailAtramatPlainGutComp from '../components/ProductDetailAtramatPlainGutComp';
import FooterComp from '../components/FooterComp';
import NavMainComp from "../components/NavMainComp";

const ProductAtramatplaingut = () => {
  return( 
   <div>
    <NavProductAtramatComp /> 
    {/* <NavMainComp />      */}
    <HeroProductAtramat />
    <ProductDetailAtramatPlainGutComp />
    <FooterComp />
   </div>
  
  ) 
}

export default ProductAtramatplaingut;