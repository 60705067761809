import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import atramartsilkf17 from "../assets/img/product/atramat/atramartsilkf17.jpg";


const ProdAtramartsilkf17 = () => {
  return (
    <div className="proddesc d-flex justify-content-center" id="ProductAtramartsilkf17">
      <Container>
         <Row>
               <Col className="my-2 align-items-center">
                  <h1 className="text-center fw-bold">Atramat Silk F1734</h1>
               </Col>
         </Row>

         <Row>          
            {/* <Col className="my-2 align-items-left"> */}
            <Col className="my-2 align-items-left py-5 px-3" data-aos="fade-up" data-aos-delay="200">
                <img src={atramartsilkf17} alt="unplash.com" className="w-20" 
                 width="850px"/>
            </Col>    
         </Row>

         <Row>
               {/* <Col className="my-2 align-items-center"> */}
               <Col className="md-2 align-items-center">               
                  <p className="text-left fw-bold">Spesification</p>
               </Col>
         </Row>

         <Row>
            <Col className="mb-5 align-items-center">
               <ul class="myUL list-group mx-3 text-black-50">
                  <li>  Natural, multifilament, braided, non-absorbable, silk suture, presented in black, white or blue, </li>
                  <li>  Obtained from the cocoon of Bombyx Mori silk worm, </li>
                  <li>  Tissue reactivity may be moderate, </li>
                  <li>  Tension is maintained over time; though it decreases until tissue capsulation occurs, </li>
                  <li>  Frequently used in tissue confrontation or ties except in Urological procedures, </li>
                  <li>  Color code : Light Blue, </li>
                  <li>  Needle length 17 mm, Strand Length 75 cm, USP 4-0, EP Metric 1.5 </li>
               </ul>
            </Col>
        </Row>           

      </Container>     
    </div>
  )
}

export default ProdAtramartsilkf17;