import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import atramartplaingut75 from "../assets/img/product/atramat/atramartplaingut75.jpg";


const ProdAtramartplaingut75 = () => {
  return (
    <div className="proddesc d-flex justify-content-center" id="ProductAtramartplaingut75">
      <Container>
         <Row>
               <Col className="my-2 align-items-center">
                  <h1 className="text-center fw-bold">Atramat Plain Gut G3721-75</h1>
               </Col>
         </Row>

         <Row>          
            {/* <Col className="my-2 align-items-left"> */}
            <Col className="my-2 align-items-left py-5 px-3" data-aos="fade-up" data-aos-delay="200">
                <img src={atramartplaingut75} alt="unplash.com" className="w-20" 
                 width="850px"/>
            </Col>    
         </Row>

         <Row>
               {/* <Col className="my-2 align-items-center"> */}
               <Col className="md-2 align-items-center">               
                  <p className="text-left fw-bold">Spesification</p>
               </Col>
         </Row>

         <Row>
            <Col className="mb-5 align-items-center">
               <ul class="myUL list-group mx-3 text-black-50">
                  <li>  Animal origin, absorbable suture with twisted multifilament, presented in yellow, </li>
                  <li>  Obtained from the small intestine serous layer of healthy bovine (free of BSE and apthose fever), </li>
                  <li>  Absorbed by fhagosytosis in approximately 50-70 days, </li>
                  <li>  The thread keeps its tensile strength between 7 and 14 days. Specific patient conditions may cause the absorption period variation, </li>
                  <li>  The thread keeps its tensile strength between 14 and 21 days. Specific patient conditions may cause the absorption period variation, </li>
                  <li>  Frequently used in easy healing tissue and that do not require permanent artificial support, </li>
                  <li>  Color code : Yellow, </li>
                  <li>  Needle length 37 mm, Strand Length 75 cm, USP 0, EP Metric 4 </li>
               </ul>
            </Col>
        </Row>           

      </Container>     
    </div>
  )
}

export default ProdAtramartplaingut75;