import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import thyroidectomy1 from "../assets/img/zepf/thyroidectomyset/thyroidectomy1.jpg";
import thyroidectomy2 from "../assets/img/zepf/thyroidectomyset/thyroidectomy2.jpg";
import thyroidectomy3 from "../assets/img/zepf/thyroidectomyset/thyroidectomy3.jpg";
import { MDBIcon, MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';

const ZepfThyroidectomy = () => {
  return (
    <div className="zepfproduct d-flex justify-content-center">
      <Container>
         <Row>
               <Col className="my-2 align-items-center" data-aos="fade-up" data-aos-delay="100">
                  <h1 className="text-left fw-bold">Thyroidectomy Set</h1>
               </Col>
         </Row> 

         <Row className="row-cols-lg-2 row-cols-md-6 row-cols-sm-2 row-cols-1 g-4">        
            {/* <Col className="my-2 align-items-left"> */}
            <Col className="my-2 align-items-left py-5 px-3" data-aos="fade-up" data-aos-delay="200">
                <img src={thyroidectomy1} alt="unplash.com" className="w-20" />
            </Col>    
            <Col className="my-2 align-items-left py-5 px-3" data-aos="fade-up" data-aos-delay="200">
                <img src={thyroidectomy2} alt="unplash.com" className="w-20" />
            </Col>    
            <Col className="my-2 align-items-left py-5 px-3" data-aos="fade-up" data-aos-delay="200">
                <img src={thyroidectomy3} alt="unplash.com" className="w-20" />
            </Col> 
         
         </Row>

         <Row>
            <MDBTable>
               <MDBTableHead class="table-success">
               <tr>
                  <th scope='col'>No</th>
                  <th scope='col'>Kode</th>
                  <th scope='col'>Deskripsi</th>
               </tr>
               </MDBTableHead>
               <MDBTableBody>
               <tr>
                  <td>1</td>
                  <td>06-1003-00</td>
                  <td>Scalpel handle no. 3</td>
               </tr>
               <tr>
                  <td>2</td>
                  <td>06-1004-00</td>
                  <td>Scalpel handle no. 4</td>
               </tr>
               <tr>
                  <td>3</td>
                  <td>08-1600-17</td>
                  <td>MAYO scissors straight 17,0 cm</td>
               </tr>  
               <tr>
                  <td>4</td>
                  <td>08-1601-17</td>
                  <td>MAYO scissors curved 17,0 cm</td>
               </tr>          
               <tr>
                  <td>5</td>
                  <td>08-2805-18</td>
                  <td>METZENBAUM scissors 18 cm TC</td>
               </tr> 
               <tr>
                  <td>6</td>
                  <td>10-1002-14</td>
                  <td>Forceps dressing 14,5 cm</td>
               </tr>  
               <tr>
                  <td>7</td>
                  <td>10-1800-12</td>
                  <td>ADSON forceps 1x2 teeth 12 cm</td>
               </tr>  
               <tr>
                  <td>8</td>
                  <td>10-1200-14</td>
                  <td>Forceps tissue 1x2 t. 14,5 cm</td>
               </tr>    
               <tr>
                  <td>9</td>
                  <td>16-1700-25</td>
                  <td>FOERSTER swab forceps serrated 25 cm</td>
               </tr>  
               <tr>
                  <td>10</td>
                  <td>54-5200-15</td>
                  <td>LAHEY goitre seizing forceps 15 cm</td>
               </tr>          
               <tr>
                  <td>11</td>
                  <td>14-1101-11</td>
                  <td>BACKHAUS towel forceps 11 cm</td>
               </tr>  
               <tr>
                  <td>12</td>
                  <td>12-2201-12</td>
                  <td>HALSTED mosquito forceps cvd 12 cm</td>
               </tr>     
               <tr>
                  <td>13</td>
                  <td>12-2401-14</td>
                  <td>KELLY hemostatic forceps cvd 14 cm</td>
               </tr>  
               <tr>
                  <td>14</td>
                  <td>12-3300-15</td>
                  <td>SPENCER WELLS (JONES) hemost. Forceps str 15 cm</td>
               </tr>
               <tr>
                  <td>15</td>
                  <td>12-3101-16</td>
                  <td>ROCHESTER PEAN hemost.forceps cvd 16 cm</td>
               </tr>   
               <tr>
                  <td>16</td>
                  <td>12-3200-14</td>
                  <td>ROCH. OCHSNER hemostat.forceps str 14 cm</td>
               </tr>  
               <tr>
                  <td>17</td>
                  <td>24-1804-16</td>
                  <td>MAYO HEGAR needleholder  16 cm TC</td>
               </tr>   
               <tr>
                  <td>18</td>
                  <td>26-1200-21</td>
                  <td>DESCHAMPS needle blunt right 21 cm</td>
               </tr>  
               <tr>
                  <td>19</td>
                  <td>26-1201-21</td>
                  <td>DESCHAMPS needle blunt left 21 cm</td>
               </tr> 
               <tr>
                  <td>20</td>
                  <td>18-1200-18</td>
                  <td>ROUX retractor double fig. 4</td>
               </tr> 
               <tr>
                  <td>21</td>
                  <td>18-2200-01</td>
                  <td>Retractor (trach.) sharp 1 pr.</td>
               </tr>                            
               <tr>
                  <td>22</td>
                  <td>18-2813-04</td>
                  <td>KOCHER retractor semi sharp 22 cm</td>
               </tr>  
               <tr>
                  <td>23</td>
                  <td>18-3402-01</td>
                  <td>KOCHER LANGENBECK retr.25x 6mm</td>
               </tr>   
               <tr>
                  <td>24</td>
                  <td>18-3408-22</td>
                  <td>GREEN retractor 22 cm</td>
               </tr>    
               <tr>
                  <td>25</td>
                  <td>18-6701-16</td>
                  <td>WEITLANER retract.blunt 16,5cm</td>
               </tr>      
               <tr>
                  <td>26</td>
                  <td>18-6900-31</td>
                  <td>BECKMANN retractor 31 cm sharp</td>
               </tr>     
               <tr>
                  <td>27</td>
                  <td>64-1200-15</td>
                  <td>ALLIS tissue forceps 15 cm 5x6 teeth</td>
               </tr> 


               </MDBTableBody>
            </MDBTable>
         </Row>        

      </Container>     
    </div>
  )
}

export default ZepfThyroidectomy;
;