import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import tht1 from "../assets/img/zepf/thtinstrument/tht1.jpg";
import tht2 from "../assets/img/zepf/thtinstrument/tht2.jpg";
import tht3 from "../assets/img/zepf/thtinstrument/tht3.jpg";
import tht4 from "../assets/img/zepf/thtinstrument/tht4.jpg";
import tht5 from "../assets/img/zepf/thtinstrument/tht5.jpg";
import tht6 from "../assets/img/zepf/thtinstrument/tht6.jpg";
import tht7 from "../assets/img/zepf/thtinstrument/tht7.jpg";
import tht8 from "../assets/img/zepf/thtinstrument/tht8.jpg";


import { MDBIcon, MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';

const ZepfTHT = () => {
  return (
    <div className="zepfproduct d-flex justify-content-center">
      <Container>
         <Row>
               <Col className="my-2 align-items-center" data-aos="fade-up" data-aos-delay="100">
                  <h1 className="text-left fw-bold">THT Instrument Set</h1>
               </Col>
         </Row> 

         <Row className="row-cols-lg-2 row-cols-md-2 row-cols-sm-2 row-cols-1 g-4">        
            {/* <Col className="my-2 align-items-left"> */}
            <Col className="my-2 align-items-left py-5 px-3" data-aos="fade-up" data-aos-delay="200">
                <img src={tht1} alt="unplash.com" className="w-20" />
            </Col>    
            <Col className="my-2 align-items-left py-5 px-3" data-aos="fade-up" data-aos-delay="200">
                <img src={tht2} alt="unplash.com" className="w-20" />
            </Col>    
            <Col className="my-2 align-items-left py-5 px-3" data-aos="fade-up" data-aos-delay="200">
                <img src={tht3} alt="unplash.com" className="w-20" />
            </Col> 
            <Col className="my-2 align-items-left py-5 px-3" data-aos="fade-up" data-aos-delay="200">
                <img src={tht4} alt="unplash.com" className="w-20" />
            </Col> 
            <Col className="my-2 align-items-left py-5 px-3" data-aos="fade-up" data-aos-delay="200">
                <img src={tht5} alt="unplash.com" className="w-20" />
            </Col> 
            <Col className="my-2 align-items-left py-5 px-3" data-aos="fade-up" data-aos-delay="200">
                <img src={tht6} alt="unplash.com" className="w-20" />
            </Col> 
            <Col className="my-2 align-items-left py-5 px-3" data-aos="fade-up" data-aos-delay="200">
                <img src={tht7} alt="unplash.com" className="w-20" />
            </Col> 
            <Col className="my-2 align-items-left py-5 px-3" data-aos="fade-up" data-aos-delay="200">
                <img src={tht8} alt="unplash.com" className="w-20" />
            </Col> 
          
         
         </Row>

         <Row>
            <MDBTable> 
               <MDBTableHead class="table-success">
               <tr>
                  <th scope='col'>No</th>
                  <th scope='col'>Kode</th>
                  <th scope='col'>Deskripsi</th>
               </tr>
               </MDBTableHead>
               <MDBTableBody>
               <tr>
                  <td>1</td>
                  <td>08-3400-11</td>
                  <td>Iris scissors str  11,5 cm</td>
               </tr>
               <tr>
                  <td>2</td>
                  <td>08-3401-11</td>
                  <td>Iris scissors  cvd 11,5 cm</td>
               </tr>
               <tr>
                  <td>3</td>
                  <td>10-2600-12</td>
                  <td>SEMKEN forceps serrated 12 cm</td>
               </tr>  
               <tr>
                  <td>4</td>
                  <td>10-2700-12</td>
                  <td>SEMKEN forceps 1x2 teeth 12 cm</td>
               </tr>          
               <tr>
                  <td>5</td>
                  <td>12-2200-12</td>
                  <td>HALSTED mosquito forceps str 12 cm</td>
               </tr> 
               <tr>
                  <td>6</td>
                  <td>12-2201-12</td>
                  <td>HALSTED mosquito forceps cvd 12 cm</td>
               </tr>  
               <tr>
                  <td>7</td>
                  <td>12-2400-14</td>
                  <td>KELLY hemostatic forceps str 14 cm</td>
               </tr>  
               <tr>
                  <td>8</td>
                  <td>12-2401-14</td>
                  <td>KELLY hemostatic forceps cvd 14 cm</td>
               </tr>    
               <tr>
                  <td>9</td>
                  <td>12-4125-19</td>
                  <td>SAWTELL tonsil forceps 19 cm</td>
               </tr>  
               <tr>
                  <td>10</td>
                  <td>14-1101-13</td>
                  <td>BACKHAUS towel forceps 13 cm</td>
               </tr>          
               <tr>
                  <td>11</td>
                  <td>18-1600-17</td>
                  <td>SENN MILLER retractor double sharp 17 cm</td>
               </tr>  
               <tr>
                  <td>12</td>
                  <td>18-2200-01</td>
                  <td>Retractor (trach.) sharp 1 pr. 16 cm</td>
               </tr>     
               <tr>
                  <td>13</td>
                  <td>18-2201-01</td>
                  <td>Retractor (trach.) blunt 1 pr. 16 cm</td>
               </tr>  
               <tr>
                  <td>14</td>
                  <td>22-1208-19</td>
                  <td>LICHTWITZ antrum trocar 1,75mm</td>
               </tr>
               <tr>
                  <td>15</td>
                  <td>24-1600-15</td>
                  <td>CRILE WOOD needleholder 15 cm</td>
               </tr>   
               <tr>
                  <td>16</td>
                  <td>44-1102-00</td>
                  <td>BUCK ear curette str.sharp 0</td>
               </tr>  
               <tr>
                  <td>17</td>
                  <td>44-1107-00</td>
                  <td>BUCK ear curette cvd.sharp 0</td>
               </tr>   
               <tr>
                  <td>18</td>
                  <td>44-1501-16</td>
                  <td>POLITZER parac.need.16,5 angl.</td>
               </tr>  
               <tr>
                  <td>19</td>
                  <td>44-2001-11</td>
                  <td>WILDE TROELTSCH ear forc.angl. 11 cm</td>
               </tr> 
               <tr>
                  <td>20</td>
                  <td>44-2003-11</td>
                  <td>WILDE TROELTSCH ear forc.1x2t. 11 cm</td>
               </tr> 
               <tr>
                  <td>21</td>
                  <td>44-2200-16</td>
                  <td>KRAUSE snare ear polypus 16 cm</td>
               </tr>                            
               <tr>
                  <td>22</td>
                  <td>44-2401-12</td>
                  <td>HARTMANN ear forceps 12 cm</td>
               </tr>  
               <tr>
                  <td>23</td>
                  <td>44-2601-08</td>
                  <td>HARTMANN ear forceps serrated 8,5 cm</td>
               </tr>   
               <tr>
                  <td>24</td>
                  <td>46-1102-01</td>
                  <td>Vienna Mod. nasal spec. infant</td>
               </tr>    
               <tr>
                  <td>25</td>
                  <td>46-1102-02</td>
                  <td>Vienna Mod. nasal specul.child</td>
               </tr>      
               <tr>
                  <td>26</td>
                  <td>46-1102-03</td>
                  <td>Vienna Mod. nasal specul.adult</td>
               </tr>     
               <tr>
                  <td>27</td>
                  <td>46-1504-20</td>
                  <td>JANSEN nasal forceps bayonet shape 20 cm</td>
               </tr> 
               <tr>
                  <td>28</td>
                  <td>46-4107-19</td>
                  <td>FREER septum elevator sharp 19 cm</td>
               </tr> 
               <tr>
                  <td>29</td>
                  <td>46-4205-16</td>
                  <td>JOSEPH periosteal elevator 16 cm</td>
               </tr>   
               <tr>
                  <td>30</td>
                  <td>46-4500-04</td>
                  <td>FREER septum chisel 16cm 4 mm</td>
               </tr>  
               <tr>
                  <td>31</td>
                  <td>46-5701-23</td>
                  <td>ASCH septum straight.forceps 23 cm</td>
               </tr>     
               <tr>
                  <td>32</td>
                  <td>48-1007-19</td>
                  <td>BRUENINGS tongue depress.19 cm</td>
               </tr> 

               <tr>
                  <td>33</td>
                  <td>48-2500-16</td>
                  <td>COLLIN tongue hold. fcps. 16cm</td>
               </tr>          

               </MDBTableBody>
            </MDBTable>

         </Row>        


      </Container>     
    </div>
  )
}

export default ZepfTHT;
;