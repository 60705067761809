import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
    

const CustAreaD = () => {
  return (
    

    <div className="custarea2 d-flex align-items-center">
      <Container>
         <Row>
            <Col>
               {/* <h4 className="text-left fw-bold" data-aos="fade-left" data-aos-delay="600"> Marketing</h4>  */}
               <h4 className="text-left fw-bold">Bandung</h4> 
               
               <ul class="list-group mx-3 text-black-50">
                  <li>  RS Hasan Sadikin, </li>
                  <li>  RSIA Islam Bandung, </li>
                  <li>  RS Santo Borromeus, </li>
                  <li>  RS Melinda 2, </li>
                  <li>  RS Hermina Arcamanik, </li>
                  <li>  RS Rajawali, </li>
                  <li>  RS Mata Cicendo, </li>
                  <li>  RS Imanuel, </li>
                  <li>  RS Santo Yusuf, </li>
                  <li>  RS Klinik Erha 21, </li>
                  <li>  RS Muhammadiyah Bandung Selatan </li>
               </ul>
            </Col>
            <Col>
               {/* <h4 className="text-left fw-bold" data-aos="fade-left" data-aos-delay="600"> Marketing</h4>  */}
               <h4 className="text-left fw-bold">Karawang, Purwakarta, Subang</h4> 
               
               <ul class="list-group mx-3 text-black-50">
                  <li>  RS Amira, </li>
                  <li>  RS Siloam Purwakarta, </li>
                  <li>  RS Bunda Fathia, </li>
                  <li>  RS Izza Cikampek, </li>
                  <li>  RS Harmori Subang, </li>
                  <li>  RS Rayhan Subang, </li>
                  <li>  RS Bhakti Husada, </li>
                  <li>  RS Delima Asih, </li>
                  <li>  RS Abdul Radjak, </li>
                  <li>  RS Izza, </li>
                  <li>  RS Mutiara Hati </li>
               </ul>
            </Col>   
            <Col>
               {/* <h4 className="text-left fw-bold" data-aos="fade-left" data-aos-delay="600"> Marketing</h4>  */}
               <h4 className="text-left fw-bold">Cirebon, Kuningan</h4> 
               
               <ul class="list-group mx-3 text-black-50">
                  <li>  RS Permata Cirebon, </li>
                  <li>  RS Sumber Kasih, </li>
                  <li>  RS Klinik Lazuardi, </li>
                  <li>  RS Cahaya Bunda, </li>
                  <li>  RS Juanda, </li>
                  <li>  RS Arya Kemuning, </li>
                  <li>  RS Sumber Waras, </li>
                  <li>  RS Sekar Kamulyan, </li>
                  <li>  RS Pertamina, </li>
                  <li>  RS Ciremai, </li>
                  <li>  RSUD Gunung Jati, </li>
                  <li>  RS Mitra Plumbon, </li>
                  <li>  RS Paru Sidawangi, </li>
                  <li>  RS Mitra Plumbon Majalengka, </li>
                  <li>  RS Permata Kuningan, </li>
                  <li>  RS Mitra Plumbon Indramayu, </li>
                  <li>  RS Sumber Hurip, </li>
                  <li>  RS MItra Husada, </li>
                  <li>  RS Pertamina Cirebon, </li>
                  <li>  RS Wijaya Kusuma, </li>
                  <li>  RSUD 45 Kuningan, </li>
                  <li>  RSUD Linggajati, </li>
                  <li>  RS Sekar Kamulyan, </li>
                  <li>  RSUD Indramayu, </li>
                  <li>  RST Ciremai, </li>
                  <li>  RS Pelabuhan </li>
               </ul>
            </Col>              
            <Col>
               {/* <h4 className="text-left fw-bold" data-aos="fade-left" data-aos-delay="600"> Marketing</h4>  */}
               <h4 className="text-left fw-bold">Garut</h4> 
               
               <ul class="list-group mx-3 text-black-50">
                  <li>  RS Medina Garut, </li>
                  <li>  RS Jasa Kartini, </li>
                  <li>  RS Aghisna Medika Sidereja, </li>
                  <li>  RS Mitra Idaman Banjar, </li>
                  <li>  RS Permata Bunda Tasik, </li>
                  <li>  RS Permata Bunda Ciamis, </li>
                  <li>  RS Tasik Medika Citratama, </li>
                  <li>  RS Nurhayati Garut </li>
               </ul>

               <h4 className="text-left fw-bold">Cilacap</h4> 
               
               <ul class="list-group mx-3 text-black-50">
                  <li>  RSI Fatimah Cilacap, </li>
                  <li>  RSU Afdila </li>
               </ul>               
            </Col>   
        </Row>         
      </Container>
    </div>
  )
}

export default CustAreaD;
