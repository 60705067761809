import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
import AllProduct from './AllProduct';
import { useNavigate } from 'react-router-dom';
import AtramatAbsorbablesutures from './AtramatAbsorbablesutures';
import AtramatNonbsorbablesutures from './AtramatNonbsorbablesutures';
import AtramatChromic from './AtramatChromic';
import Atramatbonewax from  "../components/Atramatbonewax";
import ZepfProduct from './ProductZepf';
import GelsponProduct from './ProductGelspon';
import { Navbar, Nav} from "react-bootstrap";
import NavDropdown from 'react-bootstrap/NavDropdown';

const ProductDetailAtramatComp = () => {
   const navigate = useNavigate();

   const [isVisible, setIsVisible] = useState({
      id: "comOne"
    });
    
    useEffect(() => {
      console.log(isVisible);
    }, [isVisible]);
  
    const toggleDiv = (e) => {
      setIsVisible({
          id: e.target.id
      })
    };

  return (
       <div className="productlist d-flex justify-content-left">
      <Container>
      <Navbar expand="lg" bg="warning" variant="dark">       
            <Nav className="text-left">
               <Nav.Link href="/#home" className="navtitle mx-2 text-dark" >Home</Nav.Link>
               {/* <h4 className="text-dark"> / </h4> */}

               <NavDropdown
                    title={
                      <span className="text-dark">Products</span>
                  }
                  >
                    <NavDropdown.Item className="navdropproduct" onClick={() => navigate('/productatramat')}>Atramat</NavDropdown.Item>
                    <NavDropdown.Item className="navdropproduct" onClick={() => navigate('/productzepf')}>Zepf</NavDropdown.Item>
                    <NavDropdown.Item className="navdropproduct" onClick={() => navigate('/productgelspon')}>Gelspon</NavDropdown.Item>
               </NavDropdown>
            </Nav> 
         </Navbar>



         <Row>
               <Col sm={3}>
                  <ListGroup className="listproduct" defaultActiveKey="#comOne">
                     <ListGroup.Item 

                        // onClick={() => navigate('/ProductAtramatAbsorbable')}
                        id="comOne"
                        onClick={(e) => {
                           toggleDiv(e);
                           }}
                        action href="#comOne"
                     >   
                     Absorbable Sutures
                     </ListGroup.Item>
   
                     <ListGroup.Item 
                        id="comTwo"
                        onClick={(e) => {
                           toggleDiv(e);
                           }}
                        action href="#comTwo"
                     >                  
                     Bonewax
                     </ListGroup.Item>
                     <ListGroup.Item
                        id="comThree"

                        onClick={(e) => {
                           toggleDiv(e);
                           }}
                        action href="#comThree">    

                     Non Absorbable Sutures
                     </ListGroup.Item>

               </ListGroup>
               </Col>  


               <Col className={isVisible.id === "comOne" ? `comOne` : "comOne d-none"}>
                 <AtramatAbsorbablesutures />
               </Col>  

               <Col className={isVisible.id === "comTwo" ? `comTwo` : "comTwo d-none"}>
                  <Atramatbonewax />
               </Col>   

               <Col className={isVisible.id === "comThree" ? `comThree` : "comThree d-none"}>
                  <AtramatNonbsorbablesutures />
               </Col>     

          </Row>

          {/* <Row>
            <Col className={isVisible.id === "comOne" ? `comOne` : "comOne d-none"}>
               <AllProduct />
            </Col>

            <Col className={isVisible.id === "comTwo" ? `comTwo` : "comTwo d-none"}>
               <AtramatProduct />
            </Col>

            <Col className={isVisible.id === "comThree" ? `comThree` : "comThree d-none"}>
               <ZepfProduct />
            </Col>   

            <Col className={isVisible.id === "comFour" ? `comFour` : "comFour d-none"}>
               <GelsponProduct />
            </Col>  
         </Row>              */}

       </Container>     
     </div>
  )
}

export default ProductDetailAtramatComp;