import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import atramatpga75 from "../assets/img/product/atramat/atramatpga75.jpg";


const ProdAtramatpga75 = () => {
  return (
    <div className="proddesc d-flex justify-content-center" id="ProductAtramatPGA75">
      <Container>
         <Row>
               <Col className="my-2 align-items-center">
                  <h1 className="text-center fw-bold">Atramat PGA CE2493-75</h1>
               </Col>
         </Row>

         <Row>          
            {/* <Col className="my-2 align-items-left"> */}
            <Col className="my-2 align-items-left py-5 px-3" data-aos="fade-up" data-aos-delay="200">            
                <img src={atramatpga75} alt="unplash.com" className="w-20" 
                 width="850px"/>
            </Col>    
         </Row>

         <Row>
               <Col className="md-2 align-items-center">
                  <p className="text-left fw-bold">Spesification</p>
               </Col>
         </Row>

         <Row>
            <Col className="mb-5 align-items-center">
               <ul class="myUL list-group mx-3 text-black-50">
                  <li>  Synthetic, absorbable, multifilament braided suture, presented in violet or undyed, </li>
                  <li>  Composed of a Polyglycolic acid coated with polycaprolactone and calcium stearate, </li>
                  <li>  The tissue reactivity in microscopic form in minimal, </li>
                  <li>  Absorption occurs through progressive hydrolytic action, completed between 60 and 90 days, </li>
                  <li>  The material retains approximately 70% of its initial tensile strength by the end of two weeks, </li>
                  <li>  Used in soft tissues coaptation, ligatures, and a wide variety of surgical specialties, </li>
                  <li>  Color code : Violet, </li>
                  <li>  Needle length 24 mm, USP 3-0, EP Metric 2 </li>
               </ul>
            </Col>
        </Row>           

      </Container>     
    </div>
  )
}

export default ProdAtramatpga75;