import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import tonsillectomy1 from "../assets/img/zepf/tonsillectomyset/tonsillectomy1.jpg";
import tonsillectomy2 from "../assets/img/zepf/tonsillectomyset/tonsillectomy2.jpg";
import tonsillectomy3 from "../assets/img/zepf/tonsillectomyset/tonsillectomy3.jpg";
import tonsillectomy4 from "../assets/img/zepf/tonsillectomyset/tonsillectomy4.jpg";
import tonsillectomy5 from "../assets/img/zepf/tonsillectomyset/tonsillectomy5.jpg";
import { MDBIcon, MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';

const ZepfTonsillectomy = () => {
  return (
    <div className="zepfproduct d-flex justify-content-center">
      <Container>
      <Row>
               <Col className="my-2 align-items-center" data-aos="fade-up" data-aos-delay="100">
                  <h1 className="text-left fw-bold">Tonsillectomy Set</h1>
               </Col>
         </Row> 

         <Row className="row-cols-lg-2 row-cols-md-6 row-cols-sm-2 row-cols-1 g-4">        
            {/* <Col className="my-2 align-items-left"> */}
            <Col className="my-2 align-items-left py-5 px-3" data-aos="fade-up" data-aos-delay="200">
                <img src={tonsillectomy1} alt="unplash.com" className="w-20" />
            </Col>    
            <Col className="my-2 align-items-left py-5 px-3" data-aos="fade-up" data-aos-delay="200">
                <img src={tonsillectomy2} alt="unplash.com" className="w-20" />
            </Col>    
            <Col className="my-2 align-items-left py-5 px-3" data-aos="fade-up" data-aos-delay="200">
                <img src={tonsillectomy3} alt="unplash.com" className="w-20" />
            </Col> 
            <Col className="my-2 align-items-left py-5 px-3" data-aos="fade-up" data-aos-delay="200">
                <img src={tonsillectomy4} alt="unplash.com" className="w-20" />
            </Col> 
            <Col className="my-2 align-items-left py-5 px-3" data-aos="fade-up" data-aos-delay="200">
                <img src={tonsillectomy5} alt="unplash.com" className="w-20" />
            </Col> 
         
         </Row>

         <Row>
            <MDBTable>
               <MDBTableHead class="table-success">
               <tr>
                  <th scope='col'>No</th>
                  <th scope='col'>Kode</th>
                  <th scope='col'>Deskripsi</th>
               </tr>
               </MDBTableHead>
               <MDBTableBody>
               <tr>
                  <td>1</td>
                  <td>04-1500-27</td>
                  <td>YANKAUER aspiration tube 27 cm</td>
               </tr>
               <tr>
                  <td>2</td>
                  <td>06-1003-00</td>
                  <td>Scalpel handle no. 3</td>
               </tr>
               <tr>
                  <td>3</td>
                  <td>08-1601-17</td>
                  <td>MAYO scissors curved 17,0 cm</td>
               </tr>  
               <tr>
                  <td>4</td>
                  <td>08-2801-18</td>
                  <td>METZENBAUM scissors cvd. 18 cm</td>
               </tr>          
               <tr>
                  <td>5</td>
                  <td>12-3101-16</td>
                  <td>ROCHESTER PEAN hemost.forceps cvd 16 cm</td>
               </tr> 
               <tr>
                  <td>6</td>
                  <td>12-4127-19</td>
                  <td>SCHNIDT (BOETTCHER) tonsil forceps 19 cm</td>
               </tr>  
               <tr>
                  <td>7</td>
                  <td>14-1101-13</td>
                  <td>BACKHAUS towel forceps 13 cm</td>
               </tr>  
               <tr>
                  <td>8</td>
                  <td>16-1700-25</td>
                  <td>FOERSTER swab forceps serrated 25 cm</td>
               </tr>    
               <tr>
                  <td>9</td>
                  <td>24-1600-18</td>
                  <td>CRILE WOOD needleholder 18 cm</td>
               </tr>  
               <tr>
                  <td>10</td>
                  <td>50-1301-22</td>
                  <td>HURD tonsil dissector 22 cm</td>
               </tr>          
               <tr>
                  <td>11</td>
                  <td>50-1633-20</td>
                  <td>ABRAHAM tonsil knife 20 cm</td>
               </tr>  
               <tr>
                  <td>12</td>
                  <td>50-2005-18</td>
                  <td>WHITE tonsil seizing forceps 18 cm</td>
               </tr>     
               <tr>
                  <td>13</td>
                  <td>48-3102-00</td>
                  <td>DAVIS BOYLE mouth gag complete</td>
               </tr>  
               <tr>
                  <td>14</td>
                  <td>50-1305-23</td>
                  <td>HENKE tonsil elevator 23 cm</td>
               </tr>
               <tr>
                  <td>15</td>
                  <td>50-2009-20</td>
                  <td>BLOHMKE tonsil seizing forceps 20 cm</td>
               </tr>   
               <tr>
                  <td>16</td>
                  <td>64-1200-15</td>
                  <td>ALLIS tissue forceps 15cm 5x6t</td>
               </tr>  
               <tr>
                  <td>17</td>
                  <td>48-3345-00</td>
                  <td>DRAFFIN bipod for mouth gags</td>
               </tr>   
               <tr>
                  <td>18</td>
                  <td>88-3855-11</td>
                  <td>Container body alu silver 1/2</td>
               </tr>  
               <tr>
                  <td>19</td>
                  <td>88-3881-11</td>
                  <td>Container lid alu silver 1/2</td>
               </tr> 

               </MDBTableBody>
            </MDBTable>
         </Row>                

      </Container>     
    </div>
  )
}

export default ZepfTonsillectomy;
;