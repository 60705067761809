import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import atramartpolyg75 from "../assets/img/product/atramat/atramartpolyg75.jpg";


const ProdAtramartpolyg75 = () => {
  return (
    <div className="proddesc d-flex justify-content-center" id="ProductAtramartpolyg75">
      <Container>
         <Row>
               <Col className="my-2 align-items-center">
                  <h1 className="text-center fw-bold">Atramat Polypropylene G4068-75</h1>
               </Col>
         </Row>

         <Row>          
            {/* <Col className="my-2 align-items-left"> */}
            <Col className="my-2 align-items-left py-5 px-3" data-aos="fade-up" data-aos-delay="200">
                <img src={atramartpolyg75} alt="unplash.com" className="w-20" 
                 width="850px"/>
            </Col>    
         </Row>

         <Row>
               {/* <Col className="my-2 align-items-center"> */}
               <Col className="md-2 align-items-center">               
                  <p className="text-left fw-bold">Spesification</p>
               </Col>
         </Row>

         <Row>
            <Col className="mb-5 align-items-center">
               <ul class="myUL list-group mx-3 text-black-50">
                  <li>  Synthetic, non-absorbable, monofilament suture, presented in blue, </li>
                  <li>  Extruded as a filament with uniform diameter controlled by a computer, </li>
                  <li>  The tissue reaction is minimal, </li>
                  <li>  The Polypropylene is extraordinarily stable; ideal for fulfilling its purpose for permanent tissue support without compromising its tensile strength, </li>
                  <li>  requently used to confront tissue in specialised areas. Plastic and Cardiovascular procedures among the most important ones, </li>
                  <li>  Color code : Blue, </li>
                  <li>  Needle length 40 mm, Strand Length 75 cm, USP 1, EP Metric 4 </li>
               </ul>
            </Col>
        </Row>           

      </Container>     
    </div>
  )
}

export default ProdAtramartpolyg75;