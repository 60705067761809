import React from 'react';
import NavCareerComp from "../components/NavCareerComp";
import NavMainCareerComp from "../components/NavMainCareerComp";
import HeroCareerComp from "../components/HeroCareerComp";
import CareersDetailComp from '../components/CareersDetailComp';
import FooterComp from '../components/FooterComp';


const Career = () => {
  return( 
   <div>
    {/* <NavCareerComp />  */}
    <NavMainCareerComp /> 
    <HeroCareerComp />    
    <CareersDetailComp />
    <FooterComp />
   </div>
  
  ) 
}

export default Career;

