import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import halstedmosquitoforcepscvd12 from "../assets/img/product/zepf/halstedmosquitoforcepscvd12.jpg";


const ProdZepfhalstedmosquitoforcepscvd12 = () => {
  return (
    <div className="proddesc d-flex justify-content-center" id="ProductZepfhalstedmosquitoforcepscvd12">
      <Container>
         <Row>
               <Col className="my-2 align-items-center">
                  <h1 className="text-center fw-bold">Halsted Mosquito Forceps CVD 12</h1>
               </Col>
         </Row>

         <Row>          
            {/* <Col className="my-2 align-items-left"> */}
            <Col className="my-2 align-items-left py-5 px-3" data-aos="fade-up" data-aos-delay="200">
                <img src={halstedmosquitoforcepscvd12} alt="unplash.com" className="w-20" 
                 width="850px"/>
            </Col>    
         </Row>

         <Row>
               {/* <Col className="my-2 align-items-center"> */}
               <Col className="md-2 align-items-center">               
                  <p className="text-left fw-bold">Spesification</p>
               </Col>
         </Row>

      </Container>     
    </div>
  )
}

export default ProdZepfhalstedmosquitoforcepscvd12;