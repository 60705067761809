import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Atramatplaingut from './Atramatplaingut';


const ProductDetailAtramatPlainGutComp = () => {
  return (
       <div className="productlist d-flex justify-content-left">
      <Container>
         <Row>
               <Col>
                  <Atramatplaingut />
               </Col>                                    
          </Row>
       </Container>     
     </div>
  )
}

export default ProductDetailAtramatPlainGutComp;