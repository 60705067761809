import React from 'react';
import NavProductAtramatComp from "../components/NavProductAtramatComp";
import HeroProductAtramat from "../components/HeroProductAtramat";
import HeroProductAtramatPGA from "../components/HeroProductAtramatPGA";
import ProductDetailAtramatPgaComp from '../components/ProductDetailAtramatPgaComp';
import FooterComp from '../components/FooterComp';
import NavMainComp from "../components/NavMainComp";

const ProductAtramatpga = () => {
  return( 
   <div>
    <NavProductAtramatComp /> 
    {/* <NavMainComp />      */}
    <HeroProductAtramat />
    <ProductDetailAtramatPgaComp />
    <FooterComp />
   </div>
  
  ) 
}

export default ProductAtramatpga;