import React from 'react';
import NavMainComp from "../components/NavMainComp";
import CarouselComp from "../components/CarouselComp";
import ProfileComp from '../components/ProfileComp';
import PrinciplesComp from '../components/PrinciplesComp';  
// import ProductComp from '../components/ProductComp';
import ServicesComp from '../components/ServicesComp';
// import CareersComp from '../components/CareersComp';
import FooterComp from '../components/FooterComp';



const Home = () => {
  return( 
   <div>
    <NavMainComp /> 
    <CarouselComp />
    <ProfileComp />
    <PrinciplesComp />
    {/* <ProductComp /> */}
    <ServicesComp />
    {/* <CareersComp /> */}
    <FooterComp />
   </div>
  
  ) 
}

export default Home;