import React from 'react';
import NavProductAtramatComp from "../components/NavProductAtramatComp";
import HeroProductAtramatPolyester from "../components/HeroProductAtramatPolyester";
import HeroProductAtramat from "../components/HeroProductAtramat";
import ProductDetailAtramatPolyesterComp from '../components/ProductDetailAtramatPolyesterComp';
import FooterComp from '../components/FooterComp';
import NavMainComp from "../components/NavMainComp";

const ProductAtramatPolyester = () => {
  return( 
   <div>
    <NavProductAtramatComp /> 
    {/* <NavMainComp />      */}
    <HeroProductAtramat />
    <ProductDetailAtramatPolyesterComp /> 
    <FooterComp />
   </div>
  
  ) 
}

export default ProductAtramatPolyester;