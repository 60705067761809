import React from 'react';
import NavProductAtramatComp from "../components/NavProductAtramatComp";
import HeroProductAtramat from "../components/HeroProductAtramat";
import ProductDetailAtramatAbsorbableComp from '../components/ProductDetailAtramatAbsorbableComp';
import FooterComp from '../components/FooterComp';
import NavMainComp from "../components/NavMainComp";

const ProductAtramatAbsorbableDetail = () => {
  return( 
   <div>
    <NavProductAtramatComp /> 
    {/* <NavMainComp />      */} 
    <HeroProductAtramat />
    <ProductDetailAtramatAbsorbableComp />
    <FooterComp />
   </div>
  
  ) 
}

export default ProductAtramatAbsorbableDetail;