import React from 'react';
import NavProductDesc from "../components/NavProductDesc";
import ProdAtramartsilkf17 from '../components/ProdAtramartsilkf17';
import FooterComp from '../components/FooterComp';


const ProductAtramartsilkf17 = () => {
  return( 
   <div>
    <NavProductDesc /> 
    {/* <HeroProductComp /> */}
    <ProdAtramartsilkf17 />
    <FooterComp />
   </div>
  
  ) 
}

export default ProductAtramartsilkf17;