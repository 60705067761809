import React from 'react';
import NavProductDesc from "../components/NavProductDesc";
import ProdAtramartsilkr26 from '../components/ProdAtramartsilkr26';
import FooterComp from '../components/FooterComp';


const ProductAtramartsilkr26 = () => {
  return( 
   <div>
    <NavProductDesc /> 
    {/* <HeroProductComp /> */}
    <ProdAtramartsilkr26 />
    <FooterComp />
   </div>
  
  ) 
}

export default ProductAtramartsilkr26;