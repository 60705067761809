import React from 'react';
import NavContactUsComp from "../components/NavContactUsComp";
import NavMainContactUsComp from "../components/NavMainContactUsComp";

import HeroComp from '../components/HeroComp';
import ContactUsComp from "../components/ContactUsComp";
import FooterComp from '../components/FooterComp';


const ContactUs = () => {
  return( 
   <div>
    {/* <NavContactUsComp />  */}
    <NavMainContactUsComp />     
    <HeroComp />    
    <ContactUsComp />
    <FooterComp />
   </div>
  
  ) 
}

export default ContactUs;