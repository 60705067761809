import React from 'react'
import { Container, Row, Col } from "react-bootstrap";

const HeroCustomerComp = () => {
  return (
    <div className="herocustomer">
      {<Container>
         <Row>
            <Col>
               {/* <h1 className="my-20 text-white-50 text-left fs-1 animate__animated animate__fadeInUp">Customer</h1> */}
             </Col>
         </Row>
       </Container> }      
    </div>
  )
}

export default HeroCustomerComp;
