import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';


const ContactUsComp = () => {
  return (
    <div className="contactus d-flex align-items-center">
        {/* <Row className="mb-5"> */}
        {/* <Row className="contactus row-cols-lg-1 row-cols-1">    */}
        {/* <Row className="row-cols-lg-6 row-cols-md-5 row-cols-sm-4 row-cols-1 gap-2 d-flex justify-content-center"> */}
        <Container>  
            <Row>
                <Col className="my-5 align-items-left">
                    <h4 className="text-left"> Kantor 
                    {/* Pusat  */}
                    {/* (Head Office) */}
                    </h4>

                    <p>
                        <i class="fa-solid fa-house  text-primary"></i>
                        <span className="ms-3 text-black-50">
                            Jl. Tegalan No. 1 G, Matraman Jakarta Timur. Kode Pos. 13140 
                        </span>
                    </p>
                    <p></p>

                    <p>
                    <i class="fa-solid fa-phone  text-primary"></i>
                    <span className="ms-3 text-black-50">
                    (+62)21-85911284, (+62)21-85900806, Fax: (+62)21-851 2469
                    </span>
                    </p>   

                    <iframe className= "officemap1"
                    
                    width="560"
                    height="315"
                    frameborder="0" 
                    scrolling="no" 
                    marginheight="0" 
                    marginwidth="0" 
                    src="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=en&amp;q=Jl.%20Tegalan%20No.%201%20G,%20Matraman,%20Jakarta%20Timur+(PT.%20Endo%20Medica%20Nusantara)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                        <a href="https://www.gps.ie/">gps tracker sport</a>
                    </iframe>
                </Col>
            </Row>  

            {/* <Row>   
                <Col className="my-2 align-items-left">
                    <h4 className="text-left"> Kantor Operasional (Operational Office)</h4>
                    <p>
                        <i class="fa-solid fa-house text-primary"></i>
                        <span className="ms-3 text-black-50">
                        Ruko Pondok Cabe Mutiara Blok D3 Pondok Cabe Udik Pamulang, Tangerang Selatan, Banten. Kode Pos. 15418
                        </span>
                    </p>   
                    <p></p>

                    <p>
                    <i class="fa-solid fa-phone  text-primary"></i>
                    <span className="ms-3 text-black-50">
                        (+62)813-8703-7337 
                    </span>
                    </p>   
                
                    <a>
                    <i class="fa-solid fa-envelope text-white"></i>  
                    <span className="ms-3 text-white-50">
                        endo@cbn.net.id; hrd@endomedicanusantara.com 
                    </span>
                    </a>  

                    <p></p>

                    <iframe className= "officemap1"
                    
                        width="560"
                        height="315"
                        frameborder="0" 
                        scrolling="no" 
                        marginheight="0" 
                        marginwidth="0" 
                        src="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=en&amp;q=Ruko%20Pondok%20Cabe%20Mutiara%20Blok%20D3%20Pondok%20Cabe%20Udik%20Pamulang,%20Tangerang%20Selatan,%20Banten.%20Kode%20Pos.%2015418+(PT.%20Endo%20Medica%20Nusantara)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                        <a href="https://www.gps.ie/">gps tracker sport</a>
                    </iframe>                    

                </Col>    
            </Row>  */}
        </Container>    


{/* 
                <br/>
                <h4 className="text-left"> Kantor-Kantor Perwakilan (Representative Offices)</h4>
                <h5 className="text-left"> Bogor - Jawa Barat</h5>
                <p>
                    <i class="fa-solid fa-house text-primary"></i>
                    <span className="ms-3 text-black-50">
                       Jl. Parung Hijau, Pondok Udik, Jampang Hambulu RT.01/RW.03 No.10, Kemang, Bogor. Kode Pos. 16310 
                    </span>
                </p>  
                <br/>

                <h5 className="text-left"> Kota Surabaya - Jawa timur</h5>
                <p>
                    <i class="fa-solid fa-house text-primary"></i>
                    <span className="ms-3 text-black-50">
                    JL. Mulyorejo 68A Mulyorejo –Surabaya, Jawa timur. Kode Pos. 60115
                    </span>
                </p>   
                <br/>

                <h5 className="text-left"> Jember - Jawa Timur </h5>
                <p>
                    <i class="fa-solid fa-house text-primary"></i>
                    <span className="ms-3 text-black-50">
                    Perum Jember Permai Ii Blok E18. Kec. Sumbersari Kab. Jember, Jawa Timur. Kode Pos. 68124  
                    </span>
                </p>                   


                <br/>

                <h5 className="text-left"> Yogyakarta - DIY</h5>
                <p>
                    <i class="fa-solid fa-house text-primary"></i>
                    <span className="ms-3 text-black-50">
                    Dusun Kuden, Kelurahan Sitimulyo, Kec. Piyungan, Kabupaten Bantul, Daerah Istimewa Yogyakarta. Kode Pos. 55792  
                    </span>
                </p>    
                <br/>

                <h5 className="text-left"> Kota Bandar Lampung - Bandar Lampung</h5>
                <p>
                    <i class="fa-solid fa-house text-primary"></i>
                    <span className="ms-3 text-black-50">
                    Jalan Nusantara Perumahan Pujangga Alam Garden Blok F2 No.4, Kel. Labuhan Ratu, Kota Bandar Lampung. Kode Pos. 35132  
                    </span>
                </p> 
                <br/>
         

                <h5 className="text-left"> Palembang - Sumatera Selatan</h5>
                <p>
                    <i class="fa-solid fa-house text-primary"></i>
                    <span className="ms-3 text-black-50">
                    Jln. Padat Karya No. 95 Kel. Srimulya Kecamatan Sematang Borang Palembang. Kode Pos. 30162
                    </span>
                </p>
                <br/>

                <h5 className="text-left"> Pangkal Pinang - Bangka Belitung</h5>
                <p>
                    <i class="fa-solid fa-house text-primary"></i>
                    <span className="ms-3 text-black-50">
                    Jl. Depati Hamzah No 94. Kel. Air Itam, Kec. Bukit Intan Kota Pangkal Pinang. Kode Pos. 33684  
                    </span>
                </p>                         
                <br/>
              

                <h5 className="text-left"> Medan - Sumatera Utara</h5>
                <p>
                    <i class="fa-solid fa-house text-primary"></i>
                    <span className="ms-3 text-black-50">
                    Perumahan Setia Budi Estate Jl. Pasar 1 Tj Sari Blok A-18. Kec Medan Selayang, Medan. Kode Pos. 20133  
                    </span>
                </p>                  
                <br/>

                <h5 className="text-left"> Pakanbaru - Riau</h5>
                <p>
                    <i class="fa-solid fa-house text-primary"></i>
                    <span className="ms-3 text-black-50">
                    Jl. Melati, GG. Melati 1 No. 12 Kec. Senapelan Pekanbaru (Kantor PKU). Kode Pos. 28156  
                    </span>
                </p>  
                <br/>

                <h5 className="text-left"> Kota Lhokseumawe - Banda Aceh</h5>
                <p>
                    <i class="fa-solid fa-house text-primary"></i>
                    <span className="ms-3 text-black-50">
                    Darussalam, Jl. Teumpok Teurendam, No 34. Kec, Banda Sakti, Kota Lhokseumawe. Kode Pos. 33684  
                    </span>
                </p>  */}
 
    </div>
  )
}

export default ContactUsComp;
