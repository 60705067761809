import React from 'react'
import { Container, Row, Col } from "react-bootstrap";

const HeroComp = () => {
  return (
    <div className="hero">
      <Container>
         <Row>
            <Col>
               <h1 className="text-white text-left animate__animated animate__fadeInUp">Contact Us</h1> 
             </Col>
         </Row>

       </Container>       
    </div>
  )
}

export default HeroComp;

