import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import suture1 from "../assets/img/zepf/sutureset/suture1.jpg";
import suture2 from "../assets/img/zepf/sutureset/suture2.jpg";
import suture3 from "../assets/img/zepf/sutureset/suture3.jpg";
import { MDBIcon, MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';


const ZepfSuture = () => {
  return (
    <div className="zepfproduct d-flex justify-content-center">
      <Container>
      <Row>
               <Col className="my-2 align-items-center" data-aos="fade-up" data-aos-delay="100">
                  <h1 className="text-left fw-bold">Suture Set</h1>
               </Col>
         </Row> 

         <Row className="row-cols-lg-2 row-cols-md-6 row-cols-sm-2 row-cols-1 g-4">        
            {/* <Col className="my-2 align-items-left"> */}
            <Col className="my-2 align-items-left py-5 px-3" data-aos="fade-up" data-aos-delay="200">
                <img src={suture1} alt="unplash.com" className="w-20" />
            </Col>    
            <Col className="my-2 align-items-left py-5 px-3" data-aos="fade-up" data-aos-delay="200">
                <img src={suture2} alt="unplash.com" className="w-20" />
            </Col>    
            <Col className="my-2 align-items-left py-5 px-3" data-aos="fade-up" data-aos-delay="200">
                <img src={suture3} alt="unplash.com" className="w-20" />
            </Col> 
         
         </Row>

         <Row>
         <MDBTable>
               <MDBTableHead class="table-success">
               <tr>
                  <th scope='col'>No</th>
                  <th scope='col'>Kode</th>
                  <th scope='col'>Deskripsi</th>
               </tr>
               </MDBTableHead>
               <MDBTableBody>
               <tr>
                  <td>1</td>
                  <td>16-1700-20</td>
                  <td>FOERSTER swab forceps serrated 20 cm</td>
               </tr>
               <tr>
                  <td>2</td>
                  <td>24-1800-14</td>
                  <td>MAYO HEGAR needleholder 14 cm</td>
               </tr>
               <tr>
                  <td>3</td>
                  <td>12-2500-14</td>
                  <td>CRILE hemostatic forceps str 14 cm</td>
               </tr>  
               <tr>
                  <td>4</td>
                  <td>12-2501-14</td>
                  <td>CRILE hemostatic forceps cvd 14 cm</td>
               </tr>          
               <tr>
                  <td>5</td>
                  <td>08-1100-14</td>
                  <td>Scissors, bl/sh str 14,5cm</td>
               </tr> 
               <tr>
                  <td>6</td>
                  <td>08-8009-15</td>
                  <td>HEATH ligature scissors 15 cm</td>
               </tr>  
               <tr>
                  <td>7</td>
                  <td>10-1200-14</td>
                  <td>Forceps tissue 1x2 t. 14,5 cm</td>
               </tr>  
               <tr>
                  <td>8</td>
                  <td>10-1002-14</td>
                  <td>Forceps dressing 14,5 cm</td>
               </tr>    
               <tr>
                  <td>9</td>
                  <td>10-1002-13</td>
                  <td>Forceps dressing 13,0 cm</td>
               </tr>  
               <tr>
                  <td>10</td>
                  <td>88-1300-08</td>
                  <td>Round bowl 18/8 diam. =  80 mm</td>
               </tr>          
               <tr>
                  <td>11</td>
                  <td>88-1309-25</td>
                  <td>Kidney bowl 18/10 medium 25 cm</td>
               </tr>  
               <tr>
                  <td>12</td>
                  <td>Lokal</td>
                  <td>Bak instrument besar</td>
               </tr>     
     

               </MDBTableBody>
            </MDBTable>
         </Row>        

      </Container>     
    </div>
  )
}

export default ZepfSuture;
;