import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import customer from "../assets/img/customer/customer.jpg";
import ButtonGroupClient from './ButtonGroupClient';
import CustAreaA from './CustAreaA';
import CustAreaB from './CustAreaB';
import CustAreaC from './CustAreaC';
import CustAreaD from './CustAreaD';
import CustAreaE from './CustAreaE';
import CustAreaF from './CustAreaF';


const CustomerComp = () => {
  return (
    <div className="customer d-flex justify-content-center" id="product">
      <Container>
        <Row>
            <Col className="my-5 align-items-left">
                <img src={customer} alt="unplash.com" className="w-200" 
                 width="850px"/>
            </Col>                                   
            <Col className="my-5">
               {/* <h1 className="text-center fw-bold">Products</h1> */}
               <p className="text-black-50">
                   Kami memiliki hubungan baik dengan semua pelanggan kami diantaranya dari beberapa Rumah Sakit yang berlokasi dan tersebar di seluruh Indonesia.
               </p>
            
            </Col>
        </Row>
        {/* <Row>    
            <Col className="align-items-center">
               <ButtonGroupClient />
            </Col>
        </Row>  */}
         <Row>    
            <Col className="align-items-center">
               <CustAreaA />
            </Col>
        </Row>   
        <Row>    
            <Col className="align-items-center">
               <CustAreaB />
            </Col>
        </Row>              
        <Row>    
            <Col className="align-items-center">
               <CustAreaC />
            </Col>
        </Row>     
        <Row>    
            <Col className="align-items-center">
               <CustAreaD />
            </Col>
        </Row>    
        <Row>    
            <Col className="align-items-center">
               <CustAreaE />
            </Col>
        </Row>         
        <Row>    
            <Col className="align-items-center">
               <CustAreaF />
            </Col>
        </Row>                              

      </Container>

    </div>
 )
}

export default CustomerComp;
