import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import nylon from "../assets/img/atramat/nonabsorbable/nylon.jpg";
import polyester from "../assets/img/atramat/nonabsorbable/polyester.jpg";
import polypropylene from "../assets/img/atramat/nonabsorbable/polypropylene.jpg";
import silk from "../assets/img/atramat/nonabsorbable/silk.jpg";
import { useNavigate } from 'react-router-dom';


const AtramatNonbsorbablesutures = () => {
    const navigate = useNavigate();

  return (
    <div className="cardproduct d-flex justify-content-center" id="cardproduct">
      <Container>
        {/* <Row className="row-cols-lg-2 row-cols-md-2 row-cols-sm-2 row-cols-1 g-1"> */}
        <Row>         
            <Col>
                <Card
                className="text-dark card-has-bg click-col"
                cover={<img alt="example" src={nylon} />}
                onClick={() => navigate('/ProductAtramatNylon')}
                >

                <Card.Img src={nylon} variant="top" />
                
                <Card.Img 
                    classname="card-img-overlay d-flex flex-column" />          

                {/* <Card.Body>
                    <small classname="card-meta mb-2">EMN National Meeting</small>
                    <h4 classname="card-title mt-0 ">
                        <a classname="text-dark" herf="https://creativemanner.com">Meeting Nasional di Hotel Grand Mecure, Bandung - Jawa Barat</a>
                    </h4>
                    <small><i classname="far fa-clock"></i> 19-23 Juli 2023</small>
                </Card.Body>      */}
                </Card>
            </Col>

            <Col>
                <Card
                className="text-dark card-has-bg click-col"
                cover={<img alt="example" src={polyester} />}
                onClick={() => navigate('/ProductAtramatPolyester')}
                >

                <Card.Img src={polyester} variant="top" />
                
                <Card.Img 
                    classname="card-img-overlay d-flex flex-column" />          

                {/* <Card.Body>
                    <small classname="card-meta mb-2">EMN National Meeting</small>
                    <h4 classname="card-title mt-0 ">
                        <a classname="text-dark" herf="https://creativemanner.com">Meeting Nasional di Hotel Grand Mecure, Bandung - Jawa Barat</a>
                    </h4>
                    <small><i classname="far fa-clock"></i> 19-23 Juli 2023</small>
                </Card.Body>      */}
                </Card>
            </Col>

            <Col>
                <Card
                className="text-dark card-has-bg click-col"
                cover={<img alt="example" src={polypropylene} />}
                onClick={() => navigate('/ProductAtramatPolypropelene')}
                >

                <Card.Img src={polypropylene} variant="top" />
                
                <Card.Img 
                    classname="card-img-overlay d-flex flex-column" />          

                {/* <Card.Body>
                    <small classname="card-meta mb-2">EMN National Meeting</small>
                    <h4 classname="card-title mt-0 ">
                        <a classname="text-dark" herf="https://creativemanner.com">Meeting Nasional di Hotel Grand Mecure, Bandung - Jawa Barat</a>
                    </h4>
                    <small><i classname="far fa-clock"></i> 19-23 Juli 2023</small>
                </Card.Body>      */}
                </Card>
            </Col>

            <Col>
                <Card
                className="text-dark card-has-bg click-col"
                cover={<img alt="example" src={polypropylene} />}
                onClick={() => navigate('/ProductAtramatSilk')}
                >

                <Card.Img src={silk} variant="top" />
                
                <Card.Img 
                    classname="card-img-overlay d-flex flex-column" />          

                {/* <Card.Body>
                    <small classname="card-meta mb-2">EMN National Meeting</small>
                    <h4 classname="card-title mt-0 ">
                        <a classname="text-dark" herf="https://creativemanner.com">Meeting Nasional di Hotel Grand Mecure, Bandung - Jawa Barat</a>
                    </h4>
                    <small><i classname="far fa-clock"></i> 19-23 Juli 2023</small>
                </Card.Body>      */}
                </Card>
            </Col>        
           
       </Row>
                 

      </Container>
    </div>
  )
}

export default AtramatNonbsorbablesutures;
;