import React from 'react';
import NavEventsComp from "../components/NavEventsComp";
import NavMainEventComp from "../components/NavMainEventComp";
import HeroEventsComp from '../components/HeroEventsComp';
import EventsCompDetail2 from '../components/EventsCompDetail2';
import FooterComp from '../components/FooterComp';


const EventsDetail2 = () => {
  return( 
   <div>
    {/* <NavEventsComp />  */}
    <NavMainEventComp />     
    <HeroEventsComp />     
    <EventsCompDetail2 />
    <FooterComp />
   </div>
  
  ) 
}

export default EventsDetail2;