import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import polyester1 from "../assets/img/atramat/nonabsorbable/polyester/polyester1.jpg";
import polyester2 from "../assets/img/atramat/nonabsorbable/polyester/polyester2.jpg";
import { Navbar, Nav} from "react-bootstrap";
import { useNavigate } from 'react-router-dom';

const Atramatpolyester = () => {
  const navigate = useNavigate();

  return (
    <div className="atramatproduct d-flex justify-content-center">
      <Container>
         <Navbar expand="lg" bg="warning" variant="dark">       
            <Nav className="text-left">
               <Nav.Link href="/#home" className="navtitle mx-2 text-dark" >Home</Nav.Link>
               {/* <h4 className="text-dark"> / </h4> */}
               <Nav.Link onClick={() => navigate('/ProductAtramatNonAbsorbableDetail')} className="navtitle mx-2 text-dark" >Non Absorbable Sutures </Nav.Link>
                             
            </Nav> 
         </Navbar>

         <Row>
               <Col className="my-5 align-items-center" data-aos="fade-up" data-aos-delay="100">
                  <h1 className="text-left fw-bold">Polyester</h1>
               </Col>
         </Row> 

         <Row className="row-cols-lg-2 row-cols-md-2 row-cols-sm-2 row-cols-1 g-4">        
            {/* <Col className="my-2 align-items-left"> */}
            <Col className="my-2 align-items-left py-5 px-3" data-aos="fade-up" data-aos-delay="200">
                <img src={polyester1} alt="unplash.com" className="w-20" />
            </Col>    

            <Col className="my-2 align-items-left py-5 px-3" data-aos="fade-up" data-aos-delay="400">
                <img src={polyester2} alt="unplash.com" className="w-20" />
            </Col>               
         </Row>


         <Row>
               {/* <Col className="my-2 align-items-center"> */}
               <Col className="md-2 align-items-center">               
                  <h5 className="text-left text-black-50">Diindikasikan untuk digunakan pada prosedur kardiovaskular seperti: penggantian katup jantung aorta, mitral dan trikuspid, serta penutupan tulang dada.</h5>
               </Col>
         </Row>

         <Row>
               {/* <Col className="my-2 align-items-center"> */}
               <Col className="md-2 align-items-center">               
                  <p className="text-left fw-bold">Informasi teknis :</p>
               </Col>
         </Row>

         <Row>
            <Col className="mb-5 align-items-center">
               <ul class="myUL list-group mx-5 text-black-50">
                  <li>  Disusun oleh: Serat Poliester Tereftalat yang Dipersiapkan dengan Asam atau Ester dan Etilen Glikol, </li>
                  <li>  Pelapis: Silikon, Teflon, </li>
                  <li>  Warna untaian: Putih, Hijau </li>
               </ul>
            </Col>
        </Row>            


         <Row>
               {/* <Col className="my-2 align-items-center"> */}
               <Col className="md-2 align-items-center">               
                  <p className="text-left fw-bold">Karakteristik :</p>
               </Col>
         </Row>

         <Row>
            <Col className="mb-5 align-items-center">
               <ul class="myUL list-group mx-5 text-black-50">
                  <li>  Ideal sebagai penopang permanen, tanpa mengorbankan kekuatan tariknya, </li>
                  <li>  Keamanan yang lebih baik pada simpul dengan teknik bedah yang sesuai, </li>
                  <li>  Biokompatibilitas, kelembutan, menawarkan tarikan jaringan yang minimal, simpul simpul yang memadai yang memberikan keamanan yang sangat baik, </li>
                  <li>  Ketahanan terhadap fleksi berulang yang ideal untuk prosedur kardiovaskular yang membutuhkan dukungan penggerak jantung pada prostesis jantung atau anastomosis vaskular, </li>
                  <li>  Untuk informasi lebih lanjut, lihat petunjuk penggunaan </li>
               </ul>
            </Col>
        </Row>           

      </Container>     
    </div>
  )
}

export default Atramatpolyester;
;