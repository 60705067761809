import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import chromic from "../assets/img/atramat/absorbablesutures/chromicgut.jpg";
import pdx from "../assets/img/atramat/absorbablesutures/pdx.jpg";
import pga from "../assets/img/atramat/absorbablesutures/pga.jpg";
import pgarapid from "../assets/img/atramat/absorbablesutures/pgarapid.jpg";
import pgc25 from "../assets/img/atramat/absorbablesutures/pgc25.jpg";
import plain from "../assets/img/atramat/absorbablesutures/plain.jpg";
import { useNavigate } from 'react-router-dom';


const AtramatAbsorbablesutures = () => {
    const navigate = useNavigate();

  return (
    <div className="cardproduct d-flex justify-content-center" id="cardproduct">
      <Container>
      {/* <Row className="row-cols-lg-2 row-cols-md-2 row-cols-sm-2 row-cols-1 g-2"> */}
      <Row >
            {/* <Col className="col-sm-12 col-md-6 col-lg-4 mb-4"> */}
            <Col>
                <Card
                className="text-dark card-has-bg click-col"
                cover={<img alt="example" src={chromic} />}
                onClick={() => navigate('/ProductAtramatchromic')}
                >

                <Card.Img src={chromic} variant="top" />
                
                <Card.Img 
                    classname="card-img-overlay d-flex flex-column" />          
                </Card>
            </Col>

            <Col>
                <Card
                className="text-dark card-has-bg click-col"
                cover={<img alt="example" src={pdx} />}
                onClick={() => navigate('/ProductAtramatpdx')}
                >

                <Card.Img src={pdx} variant="top" />
                
                <Card.Img 
                    classname="card-img-overlay d-flex flex-column" />          
                </Card>
            </Col>

            <Col>
                <Card
                className="text-dark card-has-bg click-col"
                cover={<img alt="example" src={pga} />}
                onClick={() => navigate('/ProductAtramatpga')}
                >

                <Card.Img src={pga} variant="top" />
                
                <Card.Img 
                    classname="card-img-overlay d-flex flex-column" />          

                {/* <Card.Body>
                    <small classname="card-meta mb-2">EMN National Meeting</small>
                    <h4 classname="card-title mt-0 ">
                        <a classname="text-dark" herf="https://creativemanner.com">Meeting Nasional di Hotel Grand Mecure, Bandung - Jawa Barat</a>
                    </h4>
                    <small><i classname="far fa-clock"></i> 19-23 Juli 2023</small>
                </Card.Body>      */}
                </Card>
            </Col>

            <Col>
                <Card
                className="text-dark card-has-bg click-col"
                cover={<img alt="example" src={pgarapid} />}
                onClick={() => navigate('/ProductAtramatpgarapid')}
                >

                <Card.Img src={pgarapid} variant="top" />
                
                <Card.Img 
                    classname="card-img-overlay d-flex flex-column" />          

                {/* <Card.Body>
                    <small classname="card-meta mb-2">EMN National Meeting</small>
                    <h4 classname="card-title mt-0 ">
                        <a classname="text-dark" herf="https://creativemanner.com">Meeting Nasional di Hotel Grand Mecure, Bandung - Jawa Barat</a>
                    </h4>
                    <small><i classname="far fa-clock"></i> 19-23 Juli 2023</small>
                </Card.Body>      */}
                </Card>
            </Col>

            <Col>
                <Card
                className="text-dark card-has-bg click-col"
                cover={<img alt="example" src={pgc25} />}
                onClick={() => navigate('/ProductAtramatpgc')}
                >

                <Card.Img src={pgc25} variant="top" />
                
                <Card.Img 
                    classname="card-img-overlay d-flex flex-column" />          

                {/* <Card.Body>
                    <small classname="card-meta mb-2">EMN National Meeting</small>
                    <h4 classname="card-title mt-0 ">
                        <a classname="text-dark" herf="https://creativemanner.com">Meeting Nasional di Hotel Grand Mecure, Bandung - Jawa Barat</a>
                    </h4>
                    <small><i classname="far fa-clock"></i> 19-23 Juli 2023</small>
                </Card.Body>      */}
                </Card>
            </Col>        


            <Col>
                <Card
                className="text-dark card-has-bg click-col"
                cover={<img alt="example" src={plain} />}
                onClick={() => navigate('/ProductAtramatplaingut')}
                >

                <Card.Img src={plain} variant="top" />
                
                <Card.Img 
                    classname="card-img-overlay d-flex flex-column" />          

                {/* <Card.Body>
                    <small classname="card-meta mb-2">EMN National Meeting</small>
                    <h4 classname="card-title mt-0 ">
                        <a classname="text-dark" herf="https://creativemanner.com">Meeting Nasional di Hotel Grand Mecure, Bandung - Jawa Barat</a>
                    </h4>
                    <small><i classname="far fa-clock"></i> 19-23 Juli 2023</small>
                </Card.Body>      */}
                </Card>
            </Col>                 
       </Row>
                 

      </Container>
    </div>
  )
}

export default AtramatAbsorbablesutures;
;