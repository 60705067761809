import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from "react-bootstrap/Button";
import atramart from "../assets/img/principles/atramat.jpg";

import AtramatList from './AtramatList';

const CardAtramat = () => {
  return (
    <div>
        {/* <Card sx={{ maxWidth: 280 }}> */}
        <Card className="cardprinciples" >         
            <CardMedia
            component="img"
            alt="green iguana"
            height="140"
            image={atramart}
            />
                <CardActions  class="text-center mb-2" >
                <Button 
                className='cardbtn'
                color="primary" 
            
                onClick={(e) => {
                e.preventDefault();
                window.open('https://www.atramat.com/', '_blank');
                // window.location.href='https://www.atramat.com/';
           
                }}
                >
                    
                Learn More
                </Button>
            </CardActions>                     
        </Card>  
    </div>
  )
}

export default CardAtramat;
