import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
import ZepfTHT from './ZepfTHT';
import ZepfSuture from './ZepfSuture';
import ZepfThyroidectomy from './ZepfThyroidectomy';
import ZepfTonsillectomy from './ZepfTonsillectomy';
import ZepfCataract from './ZepfCataract';
import { Navbar, Nav} from "react-bootstrap";
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useNavigate } from 'react-router-dom';


const ProductDetailZepfComp = () => {

   const navigate = useNavigate();

   const [isVisible, setIsVisible] = useState({
      id: "comOne"
    });
    
    useEffect(() => {
      console.log(isVisible);
    }, [isVisible]);
  
    const toggleDiv = (e) => {
      setIsVisible({
          id: e.target.id
      })
    };   

  return (
    <div className="productlist d-flex justify-content-left">
      <Container>
         <Navbar expand="lg" bg="warning" variant="dark">       
            <Nav className="text-left">
               <Nav.Link href="/#home" className="navtitle mx-2 text-dark" >Home</Nav.Link>
               {/* <h4 className="text-dark"> / </h4> */}

               <NavDropdown 
                    title={
                      <span className="text-dark">Products</span>
                  }
                  >
                    <NavDropdown.Item className="navdropproduct" onClick={() => navigate('/productatramat')}>Atramat</NavDropdown.Item>
                    <NavDropdown.Item className="navdropproduct" onClick={() => navigate('/productzepf')}>Zepf</NavDropdown.Item>
                    <NavDropdown.Item className="navdropproduct" onClick={() => navigate('/productgelspon')}>Gelspon</NavDropdown.Item>
               </NavDropdown>
            </Nav> 
         </Navbar>

         <Row>
               <Col sm={3}>
                  <ListGroup className="listproduct" defaultActiveKey="#comOne">
                     <ListGroup.Item 

                        // onClick={() => navigate('/ProductAtramatAbsorbable')}
                        id="comOne"
                        onClick={(e) => {
                           toggleDiv(e);
                           }}
                        action href="#comOne"
                     >   
                     THT Instrument Set

                    </ListGroup.Item>

                     <ListGroup.Item 

                        // onClick={() => navigate('/ProductAtramatAbsorbable')}
                        id="comTwo"
                        onClick={(e) => {
                           toggleDiv(e);
                           }}
                        action href="#comTwo"
                     >   
                     Suture Set

                    </ListGroup.Item>

                     <ListGroup.Item 

                        // onClick={() => navigate('/ProductAtramatAbsorbable')}
                        id="comThree"
                        onClick={(e) => {
                           toggleDiv(e);
                           }}
                        action href="#comThree"
                     >   
                     Thyroidectomy Set

                    </ListGroup.Item>

                     <ListGroup.Item 

                        // onClick={() => navigate('/ProductAtramatAbsorbable')}
                        id="comFour"
                        onClick={(e) => {
                           toggleDiv(e);
                           }}
                        action href="#comFour"
                     >   
                     Tonsillectomy Set

                    </ListGroup.Item>       


                     <ListGroup.Item 

                        // onClick={() => navigate('/ProductAtramatAbsorbable')}
                        id="comFive"
                        onClick={(e) => {
                           toggleDiv(e);
                           }}
                        action href="#comFive"
                     >   
                     Cataract Set

                    </ListGroup.Item>                                   
                  </ListGroup>
               </Col>            

               <Col className={isVisible.id === "comOne" ? `comOne` : "comOne d-none"}>
                 <ZepfTHT />
               </Col>  

               <Col className={isVisible.id === "comTwo" ? `comTwo` : "comTwo d-none"}>
                  <ZepfSuture />
               </Col>  

               <Col className={isVisible.id === "comThree" ? `comThree` : "comThree d-none"}>
                  <ZepfThyroidectomy />
               </Col>     
               <Col className={isVisible.id === "comFour" ? `comFour` : "comFour d-none"}>
                  <ZepfTonsillectomy />
               </Col>   
               <Col className={isVisible.id === "comFive" ? `comFive` : "comFive d-none"}>
                  <ZepfCataract />
               </Col>  
         </Row>

      </Container>     
    </div>
  )
}

export default ProductDetailZepfComp;