import React from 'react';
import NavCustomerComp from "../components/NavCustomerComp";
import HeroCustomerComp from '../components/HeroCustomerComp';
import CustomerComp from "../components/CustomerComp";
import FooterComp from '../components/FooterComp';


const Customer = () => {
  return( 
   <div>
    <NavCustomerComp /> 
    <HeroCustomerComp />    
    <CustomerComp />
    <FooterComp />
   </div>
  
  ) 
}

export default Customer;