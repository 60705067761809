import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
    

const CustAreaA = () => {
  return (
    

    <div className="custarea1 d-flex align-items-center">
      <Container>
         <Row>
            <Col>
               {/* <h4 className="text-left fw-bold" data-aos="fade-left" data-aos-delay="600"> Marketing</h4>  */}
               <h4 className="text-left fw-bold">Jakarta Pusat</h4> 
               
               <ul class="list-group mx-3 text-black-50">
                  <li>  RS Dr. Cipto Mangunkusumo, </li>
                  <li>  RS Sint Carolus, </li>
                  <li>  RS YPK Mandiri, </li>
                  <li>  RSPAD Gatot Subroto, </li>
                  <li>  RS Menteng Mitra Afia, </li>
                  <li>  RS dr. Mintoharjo, </li>
                  <li>  RS Husada, </li>
                  <li>  RS Islam Cempaka Putih, </li>
                  <li>  RS Ridwan Moureksa, </li>
                  <li>  RS THT Proklamasi, </li>
                  <li>  RS Bedah Bina Estetika, </li>
                  <li>  RS Mitra Keluarga Kemayoran, </li>
                  <li>  RSUD Kemayoran, </li>
                  <li>  RS Hermina Kemayoran, </li>
                  <li>  RS Kramat 128, </li>
                  <li>  RS Primaya Evasari, </li>
                  <li>  RS Radjak Salemba, </li>
                  <li>  RS Yarsi, </li>
                  <li>  RSIA Bunda Jakarta, </li>
                  <li>  RSIA Tambak, </li>
                  <li>  RSIA Siloam Agora </li>
               </ul>
            </Col>
            <Col>
               {/* <h4 className="text-left fw-bold" data-aos="fade-left" data-aos-delay="600"> Marketing</h4>  */}
               <h4 className="text-left fw-bold">Jakarta Selatan</h4> 
               
               <ul class="list-group mx-3 text-black-50">
                  <li>  RSUD Kebayoran Lama, </li>
                  <li>  RSUP Fatmawati, </li>
                  <li>  RS Pusat Pertamina, </li>
                  <li>  RS MMC Jakarta, </li>
                  <li>  RS Muhammadiyah Tampur, </li>
                  <li>  RS Pondok Indah, </li>
                  <li>  RS Prikasih, </li>
                  <li>  RS Marinir Cilandak, </li>
                  <li>  RS Tria Dipa, </li>
                  <li>  RSIA Budhi Jaya, </li>
                  <li>  RS Medistra, </li>
                  <li>  RS Duren Tiga, </li>
                  <li>  RS Ali Sibroh Malisi, </li>
                  <li>  RS Aulia Jakarta, </li>
                  <li>  RS Gandaria, </li>
                  <li>  RS Pertukangan, </li>
                  <li>  RS Siloam Asri, </li>
                  <li>  RS Siloam Semanggi, </li>
                  <li>  RS Siloam TB Simatupang, </li>
                  <li>  RSUD Cipayung </li>
               </ul>
            </Col>   
            <Col>
               {/* <h4 className="text-left fw-bold" data-aos="fade-left" data-aos-delay="600"> Marketing</h4>  */}
               <h4 className="text-left fw-bold">Jakarta Timur</h4> 
               
               <ul class="list-group mx-3 text-black-50">
                  <li>  RSUP Persahabatan, </li>
                  <li>  RS Islam Pondok Kopi, </li>
                  <li>  RS Harapan Bunda, </li>
                  <li>  RS Omni Medical Center, </li>
                  <li>  RSUD Pasar Rebo, </li>
                  <li>  RS PAU Antariksa, </li>
                  <li>  RS Harapan Jayakarta, </li>
                  <li>  RSUD Budhi Asih, </li>
                  <li>  RS Bedah Rawamangun, </li>
                  <li>  RS Polri, </li>
                  <li>  RS dr. Esnawan Antariksa, </li>
                  <li>  RS Haji Jakarta, </li>
                  <li>  RS Harum Sisma Medika, </li>
                  <li>  RS Hermina Jatinegara, </li>
                  <li>  RS Kartika Pulomas, </li>
                  <li>  RS Ridwan Meureksa, </li>
                  <li>  RSIA Bunda Aliyah Pondok Bambu, </li>
                  <li>  RSIA Restu Kasih, </li>
                  <li>  RSIA Sammrie Basra </li>
               </ul>
            </Col>      

            <Col>
               {/* <h4 className="text-left fw-bold" data-aos="fade-left" data-aos-delay="600"> Marketing</h4>  */}
               <h4 className="text-left fw-bold">Jakarta Utara</h4> 
               
               <ul class="list-group mx-3 text-black-50">
                  <li>  RS Islam Sukapura, </li>
                  <li>  RS Mitra Kelapa Gading, </li>
                  <li>  RS Port Medical Center, </li>
                  <li>  RSIA Family, </li>
                  <li>  RS Atmajaya, </li>
                  <li>  RS Hermina Podomoro, </li>
                  <li>  RS dr. Sulianti Saroso, </li>
                  <li>  RSIA Santo Yusuf, </li>
                  <li>  RSU Pekerja, </li>
                  <li>  RSU Pelabuhan Jakarta </li>
               </ul>
            </Col>   

            <Col>
               {/* <h4 className="text-left fw-bold" data-aos="fade-left" data-aos-delay="600"> Marketing</h4>  */}
               <h4 className="text-left fw-bold">Jakarta Barat</h4> 
               
               <ul class="list-group mx-3 text-black-50">
                  <li>  RS Hermina Daan Mogot, </li>
                  <li>  RS Kanker Dharmais, </li>
                  <li>  RS Pelni Petamburan, </li>
                  <li>  RS Siloam Kebon Jeruk, </li>
                  <li>  RS Medika Permata Hijau, </li>
                  <li>  RS Mitra Keluarga Kalideres, </li>
                  <li>  RS Ukrida, </li>
                  <li>  RS Graha Kedoya </li>
               </ul>
            </Col>                                                           
        </Row>         
      </Container>
    </div>
  )
}

export default CustAreaA;
