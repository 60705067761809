import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import atramatpga150 from "../assets/img/product/atramatpga150.jpg";
import atramatpga75 from "../assets/img/product/atramatpga75.jpg";
import atramatpgc25 from "../assets/img/product/atramatpgc25.jpg";
import atramatnylon75n from "../assets/img/product/atramatnylon75n.jpg";
import atramatchromicgut100 from "../assets/img/product/atramatchromicgut100.jpg";
import atramatplaingut75 from "../assets/img/product/atramatplaingut75.jpg";
import atramatpolypropylene75 from "../assets/img/product/atramatpolypropylene75.jpg";
import atramatpolypropyleneg75 from "../assets/img/product/atramatpolypropyleneg75.jpg";
import atramatsilkr2632 from "../assets/img/product/atramatsilkr2632.jpg";
import atramatsilkf1734 from "../assets/img/product/atramatsilkf1734.jpg";
import zepf from "../assets/img/product/zepf.jpg";
import starion from "../assets/img/product/starion.jpg";
import CardProduct from './CardProduct';
import CardProductNew from './CardProductNew';
import ButtonGroupProduct from './ButtonGroupProduct';

import emnprofile from "../assets/img/profile/emnprofile.jpg";
import emnvideo from "../assets/img/profile/videoemn.mp4";

import doc1 from "../assets/img/doc/izinusaha.jpg";
import doc2 from "../assets/img/doc/nib.jpg";


import docatramat1 from "../assets/img/doc/izinatramatchromicgutsurgical.jpg";
import docatramat2 from "../assets/img/doc/izinatramatplaingutsurgicalsuture.jpg";
import docatramat3 from "../assets/img/doc/izinatramatpolypropylensurgical.jpg";
import docatramat4 from "../assets/img/doc/izinatramatpgarapidabsorbable.jpg";
import docatramat5 from "../assets/img/doc/izinatramatnylonsurgical.jpg";
import docatramat6 from "../assets/img/doc/izinatramatpdxpolydioxanone.jpg";
import docatramat7 from "../assets/img/doc/izinatramatpolyglycolicacid.jpg";
import docatramat8 from "../assets/img/doc/izinatramatpgc25synthetic.jpg";
import docatramat9 from "../assets/img/doc/izinatramatsilk.jpg";
import docatramat10 from "../assets/img/doc/izinatramatpolyglycolic.jpg";

import doczepf1 from "../assets/img/doc/izinzepflaparatomy.jpg";
import doczepf2 from "../assets/img/doc/izinzepfbonesurgery.jpg";
import doczepf3 from "../assets/img/doc/izinzepfcuttinginstruments.jpg";
import doczepf4 from "../assets/img/doc/izinzepfdigestivesurgery.jpg";
import doczepf5 from "../assets/img/doc/izinzepfentsurgeryinstruments.jpg";
import doczepf6 from "../assets/img/doc/izinzepfforcepsinstruments.jpg";
import doczepf7 from "../assets/img/doc/izinzepfforcepsinstruments.jpg";
import doczepf8 from "../assets/img/doc/izinzepfneedleholderinstruments.jpg";
import doczepf9 from "../assets/img/doc/izinzepfneurosurgery.jpg";
import doczepf10 from "../assets/img/doc/izinzepfopthalmicinstrument.jpg";
import doczepf11 from "../assets/img/doc/izinzepfretractorinstrument.jpg";
import doczepf12 from "../assets/img/doc/izinzepfscissorsinstruments.jpg";
import doczepf13 from "../assets/img/doc/izinzepfsterilizationinstruments.jpg";

import docgelspon1 from "../assets/img/doc/izingelsponsterilabsorbable.jpg";

import vision from "../assets/img/profile/vision.jpg";
import logoemn from "../assets/img/logo/logoemn.jpg";



const ProfileCompanyDetail = () => {
  return (
    <div className="doclicense d-flex align-items-center" id="profilecompdetail">
      <Container>

      {/* <Row className="profiles">
            <Col data-aos="fade-right" data-aos-delay="100">
                <iframe className= "officemap1 my-2"
                    
                    width="560"
                    height="315"
                    frameborder="0" 
                    scrolling="no" 
                    marginheight="0" 
                    marginwidth="0" 
                    src={emnvideo}
                    >
                </iframe>                    
            </Col> 
      </Row>       */}

      <Row>

            <Col className="my-5 align-items-left" data-aos="fade-left" data-aos-delay="100">
               {/* <h4 className="text-left fw-bold" data-aos="fade-left" data-aos-delay="600"> Marketing</h4>  */}
               <h2 className="text-left fw-bold"> Tentang Kami</h2> 
               <p className="text-left text-black-50">

                    Endo Medica Nusantara (EMN) adalah perusahaan berkembang yang mengkhususkan diri dalam impor medis
                    peralatan, bahan habis pakai, perbaikan dan persediaan terletak di Jakarta, Indonesia.
                    Endo Medica Nusantara didirikan sebagai perusahaan independen sejak Juli 2002
                    berpengalaman dalam penyediaan peralatan medis habis pakai untuk swasta dan umum
                    sektor menyediakan perlengkapan ruang operasi dan unit poliklinik serta penunjangnya
                    oleh karyawan berpengalaman di seluruh Indonesia.
                    Endo Medica Nusantara adalah perusahaan yang peduli terhadap komitmen, solusi terbaik
                    untuk segala permasalahan kesehatan khususnya pada pasien Ruang Operasi dan Poliklinik.
               </p>
               <br /> 
            
            </Col>
        </Row> 

        <Row>
            <Col className="align-items-left" data-aos="fade-right" data-aos-delay="100">
            <h4 className="text-left fw-bold"> Kami menyediakan berbagai jenis produk yang meliputi :</h4>

            <ul class="list-group mx-3 text-black-50">
            <li>  Peralatan medis (Medical Equipment) seperti Zeft, Ackerman (Laparascopy),
                    Microline Intrument & Electro Couter, </li>
            <li>  Produk Bahan Habis Pakai (Consumables Product) seperti Atramat Sutures, Indomesh Surgical, Gelspone
                    Hemostate , Pencil Counter, Nasal Silicone, Tip Cleaner & Needdle Counter </li>
            
            </ul>  
            </Col>
        </Row>               

        <Row>
            <Col className="visioncard my-2 align-items-left" data-aos="fade-left" data-aos-delay="400">

                <Card className="bg-dark text-white">
                    <Card.Img src={vision} alt="Card image" />
                    
                    <Card.ImgOverlay>
                        {/* <Card.Img classname="mr-3 rounded-circle" src={logoemn}/> */}
                        <Card.Title>Visi</Card.Title>
                        <Card.Text>
                         Kami bekerja dengan cerdas untuk menyediakan perawatan kesehatan, produk, 
                         layanan, dan membantu penyedia layanan kesehatan dengan karir mereka, 
                         dengan tetap berpegang teguh pada nilai-nilai etika dan menghormati kepedulian sosial 
                         dan lingkungan yang akan tercermin secara positif dalam kepuasan pelanggan, 
                         kinerja tim yang sangat baik, dan bisnis dengan kualitas terbaik.
                        </Card.Text>
                        <br /><br />
                        <Card.Title>Misi</Card.Title>
                        <Card.Text>
                         Untuk memperluas, mengeksplorasi dan teknik operasi Endo di Indonesia dengan 
                         memperkenalkan produk kami dengan harga yang berharga.
                        </Card.Text>                        
                    </Card.ImgOverlay>
                </Card>
            </Col>
        </Row>         


                    
      </Container>

    </div>
  )
}

export default ProfileCompanyDetail;