import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from "react-bootstrap/Button";
import zepf from "../assets/img/principles/zepf.jpg";
import AtramatList from './AtramatList';

const CardZepf = () => {
  return (
    <div>
        {/* <Card sx={{ maxWidth: 280 }}> */}
        <Card className="cardprinciples" >          
            <CardMedia
            component="img"
            alt="green iguana"
            height="140"
            image={zepf}
            />
                <CardActions  class="text-center mb-2" >
                <Button 
                className='cardbtn'
                color="primary" 
                onClick={(e) => {
                e.preventDefault();
                window.open('https://www.zepf-medical-instruments.de/English:index.asp', '_blank');                 
                // window.location.href='https://www.zepf-medical-instruments.de/English:index.asp';
                }}>
                    
                Learn More
                </Button>
            </CardActions>                     
        </Card>  
    </div>
  )
}

export default CardZepf;
