import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import profile from "../assets/img/profile/profile.jpg";
import { useNavigate } from 'react-router-dom';
import profilecomp from "../assets/pdf/company/emncompanyprofile.pdf";
import PdfViewerComp from './PdfViewerComp';


const ProfileComp = () => {
  const navigate = useNavigate();
  
  return (
    <div className="profile d-flex align-items-center" id="profile">
      <Container>
        <Row >
            <Col>
               {/* <h1 className="text-center fw-bold"  data-aos="fade-right">PT. Endo Medica Nusantara</h1> */}
               <h1 className="text-center fw-bold">PT. Endo Medica Nusantara</h1>

               {/* <p className="text-align: justify text-black-50"  data-aos="fade-up" data-aos-delay="200"> */}
               <p className="text-align: justify text-black-50">
                  PT. Endo Medica Nusantara (EMN) didirikan pada tanggal 16 Juli 2002. Perusahaan kami bergerak di bidang Importir alat-alat kesehatan yang berlokasi di Jakarta Timur. Alat-alat kesehatan kami sudah tersebar di seluruh Rumah Sakit di Indonesia. 
                  Produk alat kesehatan kami juga sudah masuk ke dalam e-Catalog.
               </p>

            </Col>
        </Row>

        <Row>
            <Col>
              <div class="d-flex justify-content-center">
                <button type="button" class="btn btn-dark" 
                  // onClick={(e) => {
                  //   e.preventDefault();
                  //   window.open('/product');     
                  //   // window.open('/product', '_blank'); 
                  // }}                                
                    onClick={() => navigate('/profilecompany')}
                    // onClick={() => navigate('/events')}
                  >
                Selengkapnya</button>
              </div>
           </Col>
         </Row>
{/* 
        <Row className="row-cols-lg-2 row-cols-1">
            <Col className="my-5 align-items-center">
                <img src={profile} alt="unplash.com" 
                className="w-200" 
                data-aos="fade-right" data-aos-delay="200"  width="850px"/>
            </Col>                        
            <Col className="align-items-center">
               <p className="text-left text-black-50" data-aos="fade-left" data-aos-delay="400">
                  Perusahaan kami bergerak di bidang Importir alat-alat kesehatan yang berlokasi di Jakarta Timur. Alat-alat kesehatan kami sudah tersebar di seluruh Rumah Sakit di Indonesia. 
                  Produk alat kesehatan kami juga sudah masuk ke dalam e-Catalog.
               </p>
            </Col>
        </Row>          */}

      </Container>

    </div>
  )
}

export default ProfileComp;
