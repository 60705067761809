import React from 'react';
import NavProductDesc from "../components/NavProductDesc";
import ProdAtramartplaingut75 from '../components/ProdAtramartplaingut75';
import FooterComp from '../components/FooterComp';


const ProductAtramartplaingut75 = () => {
  return( 
   <div>
    <NavProductDesc /> 
    {/* <HeroProductComp /> */}
    <ProdAtramartplaingut75 />
    <FooterComp />
   </div>
  
  ) 
}

export default ProductAtramartplaingut75;