import React from 'react'
import { Container, Row, Col } from "react-bootstrap";

const HeroCareerComp = () => {
  return (
    <div className="herocareer">
      <Container>
         <Row>
            <Col>
               <h1 className="text-white text-left animate__animated animate__fadeInUp">Careers</h1> 
             </Col>
         </Row>

       </Container>         
    </div>
  )
}

export default HeroCareerComp;

