import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';


const ServicesComp = () => {
  return (
    <div className="services min-vh-100 d-flex align-items-center" id="services">
      <Container>
        <Row className="mb-5">
            <Col>
               {/* <h1 className="text-center fw-bold" data-aos="fade-up">Services</h1> */}
               <h1 className="text-center fw-bold">Services</h1>
               {/* <p className="text-center text-black-50" data-aos="fade-up" data-aos-delay="200"> */}
               <p className="text-center text-black-50">

                  Kami memberikan produk bersertifikat dengan harga yang benar-benar terjangkau dan kompetitif
                  bagi konsumen, serta memberikan pelayanan terbaik secara profesional, prima, handal dan terpercaya
                  .
               </p>
            
            </Col>
        </Row>
        <Row className="row-cols-lg-3 row-cols-md-2 row-cols-1 justify-content-center">
            {/* <Col className="text-center py-5 px-3" data-aos="fade-up" data-aos-delay="400"> */}
            <Col className="text-center py-5 px-3">

                <i class="fa-solid fa-coins fs-2 mb-4"></i>
                <h5 className="fw-bold">Harga Terjangkau</h5>
                <p>Harga special, terjangkau dan kompetitif.</p>
            </Col>
            {/* <Col className="text-center py-5 px-3" data-aos="fade-up" data-aos-delay="600"> */}
            <Col className="text-center py-5 px-3">

                <i class="fa-solid fa-thumbs-up fs-2 mb-4"></i>
                <h5 className="fw-bold">Layanan Prima dan Handal</h5>
                <p>Layanan purna jual dengan solusi prima dan handal dari sumber daya manusia yang ahli dan berpengalaman.</p>
            </Col>
            <Col className="text-center py-5 px-3">
                <i class="fa-solid fa-shield-halved fs-2 mb-4"></i>
                <h5 className="fw-bold">Tersertifikasi</h5>
                <p>Semua produk telah diakui dan mempunyai sertifikasi resmi tingkat dunia.</p>
            </Col>                        
        </Row>
      </Container>

    </div>
  )
}

export default ServicesComp;
