import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import NavDropdown from 'react-bootstrap/NavDropdown';
import logoemn from "../assets/img/logo/logoemn.jpg";
import { useNavigate } from 'react-router-dom';

const NavProductGelsponComp = () => {
  const navigate = useNavigate();

    return (
        <div className="sticky-top">  
        <Navbar expand="lg" bg="dark" variant="dark">        
        <Container>
        <Navbar.Brand> 
             <a>
               <img className="navimgprod" src={logoemn} width="40px"/>
               <span className="navprod fw-bold text-white">PT. Endo Medica Nusantara</span>
             </a>          
        
        </Navbar.Brand>

        {/* <Navbar.Brand className="titleprod fw-bold text-white text-left">PT. Endo Medica Nusantara</Navbar.Brand> */}
        {/* <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse></Navbar.Collapse> */}
      </Container>
      </Navbar>
      </div>
    )
  }
  
  export default NavProductGelsponComp;