import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import eventA01 from "../assets/img/events/eventcardA01.jpg";
import eventB01 from "../assets/img/events/eventcardB01.jpg";
import eventC01 from "../assets/img/events/eventcardC01.jpg";
import { useNavigate } from 'react-router-dom';

const EventsCompDetail1 = () => {
  const navigate = useNavigate();

  return (
    <div className="newsevents d-flex justify-content-center" id="events">
      <Container>
      <Row>
          <Col className="col-sm-12 col-md-6 col-lg-4 mb-4">
            <Card
              className="text-dark card-has-bg click-col"
              cover={<img alt="example" src={eventB01} />}
              onClick={() => navigate('/EventsDetail2')}              
              // onClick={(e) => {
              //   e.preventDefault();
              //   window.open("/EventsDetail2" ,'_parent');
            
              //   }}
              >

            <Card.Img src={eventB01} variant="top" />
            
            <Card.Img 
                classname="card-img-overlay d-flex flex-column" />          

              <Card.Body>
                  <small classname="card-meta mb-2">EMN National Meeting</small>
                  <h4 classname="card-title mt-0 ">
                    <a classname="text-dark" herf="https://creativemanner.com">Meeting Nasional di Saung Dolken Sentul, Bogor - Jawa Barat</a>
                  </h4>
                  <small><i classname="far fa-clock"></i> 06-11 Februari 2024</small>
              </Card.Body>     
            </Card>
          </Col>


          <Col className="col-sm-12 col-md-6 col-lg-4 mb-4">
            <Card
              className="text-dark card-has-bg click-col"
              cover={<img alt="example" src={eventA01} />}
              onClick={() => navigate('/EventsDetail1')}
              // onClick={(e) => {
              //   e.preventDefault();
              //   window.open("/EventsDetail1" ,'_parent');
            
              //   }}
              >

            <Card.Img src={eventA01} variant="top" />
            
            <Card.Img 
                classname="card-img-overlay d-flex flex-column" />          

              <Card.Body>
                  <small classname="card-meta mb-2">EMN National Meeting</small>
                  <h4 classname="card-title mt-0 ">
                    <a classname="text-dark" herf="https://creativemanner.com">Meeting Nasional di Hotel Grand Mecure, Bandung - Jawa Barat</a>
                  </h4>
                  <small><i classname="far fa-clock"></i> 19-23 Juli 2023</small>
              </Card.Body>     
            </Card>
            
          
          </Col>

          <Col className="col-sm-12 col-md-6 col-lg-4 mb-4">
            <Card
              className="text-dark card-has-bg click-col"
              cover={<img alt="example" src={eventC01} />}
              onClick={() => navigate('/EventsDetail3')}
              // onClick={(e) => {
              //   e.preventDefault();
              //   window.open("/EventsDetail1" ,'_parent');
            
              //   }}
              >

            <Card.Img src={eventC01} variant="top" />
            
            <Card.Img 
                classname="card-img-overlay d-flex flex-column" />          

              <Card.Body>
                  <small classname="card-meta mb-2">EMN National Meeting</small>
                  <h4 classname="card-title mt-0 ">
                    <a classname="text-dark" herf="https://creativemanner.com">Pertemuan Ilmiah Tahunan XXVII POGI 2024 di Hotel Harper Kupang, Nusa Tenggara Timur</a>
                  </h4>
                  <small><i classname="far fa-clock"></i> Pra PIT 18 - 20 Juli 2024 dan PIT 22 - 24 Juli 2024</small>
              </Card.Body>     
            </Card>
            
          
          </Col>

       </Row>
                 

      </Container>

    </div>
 )
}

export default EventsCompDetail1;
