import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
    

const CustAreaC = () => {
  return (
    

    <div className="custarea2 d-flex align-items-center">
      <Container>
         <Row>
            <Col>
               {/* <h4 className="text-left fw-bold" data-aos="fade-left" data-aos-delay="600"> Marketing</h4>  */}
               <h4 className="text-left fw-bold">Tangerang</h4> 
               
               <ul class="list-group mx-3 text-black-50">
                  <li>  RS An-Nisa, </li>
                  <li>  RS Aqidah, </li>
                  <li>  RS EMC, </li>
                  <li>  RS Hermina Tangerang, </li>
                  <li>  RS Mulya, </li>
                  <li>  RS Permata Ibu, </li>
                  <li>  RS Sari Asih Ar Rahmah, </li>
                  <li>  RS Sari Asih Cileduk, </li>
                  <li>  RS Sari Asih Karawaci, </li>
                  <li>  RS Sari Asih Sangiang, </li>
                  <li>  RS Tiara, </li>
                  <li>  RSIA Muhammadiyah Cipondoh, </li>
                  <li>  RSUP Dr. Sitanala, </li>
                  <li>  RS Sari Asih Cipondoh, </li>
                  <li>  RS Insan Permata, </li>
                  <li>  RS Metro M Toha, </li>
                  <li>  RS Siloam Kelapa Dua, </li>
                  <li>  RS Mitra Keluarga Gading Serpong, </li>
                  <li>  RSIA Bunda Sejati, </li>
                  <li>  RSIA Assifa, </li>
                  <li>  RS Hermina Bitung, </li>
                  <li>  RS Kurnia Serang, </li>
                  <li>  RS Sari Asih Serang, </li>
                  <li>  RS Hermina Ciruas, </li>
                  <li>  RS Metro Cikupa, </li>
                  <li>  RS Mitra Husada, </li>
                  <li>  RS Krakatau Medika, </li>
                  <li>  RS Bedah Benggala, </li>
                  <li>  RS Budi Asih, </li>
                  <li>  RS Misi Lebak </li>
               </ul>
            </Col>
            <Col>
               {/* <h4 className="text-left fw-bold" data-aos="fade-left" data-aos-delay="600"> Marketing</h4>  */}
               <h4 className="text-left fw-bold">Tangerang Selatan</h4> 
               
               <ul class="list-group mx-3 text-black-50">
                  <li>  RS Bhinneka Bakti Husada, </li>
                  <li>  RS Buah Hati Ciputat, </li>
                  <li>  RS Bunda Dalimah, </li>
                  <li>  RS Hermina Ciputat, </li>
                  <li>  RS Hermina Serpong, </li>
                  <li>  RS Ichsan Medical Center, </li>
                  <li>  RS Medica BSD, </li>
                  <li>  RS Mitra Keluarga Bintaro, </li>
                  <li>  RS Rumah Indonesia Sehat, </li>
                  <li>  RS Sari Asih Ciputat, </li>
                  <li>  RS Syarif Hidayatullah, </li>
                  <li>  RSIA Buah Hati Pamulang, </li>
                  <li>  RSIA Cinta Kasih, </li>
                  <li>  RSIA Permata Sarana Husada, </li>
                  <li>  RS Medika Lestari, </li>
                  <li>  RS Bethsaida, </li>
                  <li>  RS Aminah </li>
               </ul>
            </Col>   
    
        </Row>         
      </Container>
    </div>
  )
}

export default CustAreaC;
