import React from 'react';
import NavProfileCompany from "../components/NavProfileCompany";
import HeroProfileCompany from "../components/HeroProfileCompany";
import ProfileCompanyDetail from '../components/ProfileCompanyDetail';
import FooterComp from '../components/FooterComp';


const ProfileCompany = () => {
  return( 
   <div>
      <NavProfileCompany /> 
      <HeroProfileCompany />
      <ProfileCompanyDetail />
      <FooterComp />
   </div>
  
  ) 
}

export default ProfileCompany;
;