import React from 'react';
import NavProductAtramatComp from "../components/NavProductAtramatComp";
import HeroProductAtramat from "../components/HeroProductAtramat";
import HeroProductAtramatChromic from "../components/HeroProductAtramatChromic";
import ProductDetailAtramatChromicComp from '../components/ProductDetailAtramatChromicComp';
import FooterComp from '../components/FooterComp';
import NavMainComp from "../components/NavMainComp";

const ProductAtramatchromic = () => {
  return( 
   <div>
    <NavProductAtramatComp /> 
    {/* <NavMainComp />      */}
    <HeroProductAtramat />
    <ProductDetailAtramatChromicComp />
    <FooterComp />
   </div>
  
  ) 
}

export default ProductAtramatchromic;