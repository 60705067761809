import React from 'react';
import NavEventsComp from "../components/NavEventsComp";
import NavMainEventComp from "../components/NavMainEventComp";

import HeroEventsComp from '../components/HeroEventsComp';
import EventsComp from '../components/EventsComp';
import FooterComp from '../components/FooterComp';


const Events = () => {
  return( 
   <div>
    {/* <NavEventsComp />  */}
    <NavMainEventComp />     
    <HeroEventsComp />    
    <EventsComp />
    <FooterComp />
   </div>
  
  ) 
}

export default Events;