import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import pdx1 from "../assets/img/atramat/absorbablesutures/pdx/pdx1.jpg";
import pdx2 from "../assets/img/atramat/absorbablesutures/pdx/pdx2.jpg";
import { Navbar, Nav} from "react-bootstrap";
import { useNavigate } from 'react-router-dom';


const Atramatpdx = () => {
  const navigate = useNavigate();

  return (
    <div className="atramatproduct d-flex justify-content-center">
      <Container>
         <Navbar expand="lg" bg="warning" variant="dark">       
            <Nav className="text-left">
               <Nav.Link href="/#home" className="navtitle mx-2 text-dark" >Home</Nav.Link>
               {/* <h4 className="text-dark"> / </h4> */}
               <Nav.Link onClick={() => navigate('/ProductAtramatAbsorbableDetail')} className="navtitle mx-2 text-dark" >Absorbable Sutures </Nav.Link>
                             
            </Nav> 
         </Navbar>


         <Row>
               <Col className="my-5 align-items-center" data-aos="fade-up" data-aos-delay="100">
                  <h1 className="text-left fw-bold">PDX</h1>
               </Col>
         </Row> 

         <Row className="row-cols-lg-2 row-cols-md-2 row-cols-sm-2 row-cols-1 g-4">        
            {/* <Col className="my-2 align-items-left"> */}
            <Col className="my-2 align-items-left py-5 px-3" data-aos="fade-up" data-aos-delay="200">
                <img src={pdx1} alt="unplash.com" className="w-20" />
            </Col>    

            <Col className="my-2 align-items-left py-5 px-3" data-aos="fade-up" data-aos-delay="400">
                <img src={pdx2} alt="unplash.com" className="w-20" />
            </Col>               
         </Row>


         <Row>
               {/* <Col className="my-2 align-items-center"> */}
               <Col className="md-2 align-items-center">               
                  <h5 className="text-left text-black-50">Diindikasikan untuk pendekatan dan/atau ligasi jaringan lunak, pada prosedur penyakit kardiovaskular anak. Jahitan ini direkomendasikan untuk menutup luka yang membutuhkan penyangga yang lebih panjang.</h5>
               </Col>
         </Row>

         <Row>
               {/* <Col className="my-2 align-items-center"> */}
               <Col className="md-2 align-items-center">               
                  <h5 className="text-left text-black-50">Kekuatan tarik: Minggu keempat 70% dari kekuatan tarik asli, minggu keenam 50%. Penyerapan total: 180 - 220 hari.</h5>
               </Col>
         </Row>

         <Row>
               {/* <Col className="my-2 align-items-center"> */}
               <Col className="md-2 align-items-center">               
                  <p className="text-left fw-bold">Informasi teknis :</p>
               </Col>
         </Row>

         <Row>
            <Col className="mb-5 align-items-center">
               <ul class="myUL list-group mx-5 text-black-50">
                  <li>  Terdiri dari: monomer p-dioksanon, </li>
                  <li>  Penyerapan: Hidrolisis, </li>
                  <li>  Penyerapan: Fagositosis, </li>
                  <li>  Warna untaian: Violet atau Tidak diwarnai </li>
               </ul>
            </Col>
        </Row>            


         <Row>
               {/* <Col className="my-2 align-items-center"> */}
               <Col className="md-2 align-items-center">               
                  <p className="text-left fw-bold">Karakteristik :</p>
               </Col>
         </Row>

         <Row>
            <Col className="mb-5 align-items-center">
               <ul class="myUL list-group mx-5 text-black-50">
                  <li>  Reaksi jaringan minimal, </li>
                  <li>  Keamanan yang lebih baik pada simpul dengan teknik bedah yang sesuai, </li>
                  <li>  Warna ungu memberikan visibilitas yang sangat baik di bidang bedah, </li>
                  <li>  Untuk informasi lebih lanjut, lihat petunjuk penggunaan </li>
               </ul>
            </Col>
        </Row>           

      </Container>     
    </div>
  )
}

export default Atramatpdx;
;