import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import event01 from "../assets/img/events/eventC01.jpg";
import event02 from "../assets/img/events/eventC02.jpg";
import event03 from "../assets/img/events/eventC03.jpg";
import event04 from "../assets/img/events/eventC04.jpg";
import event05 from "../assets/img/events/eventC05.jpg";
import event06 from "../assets/img/events/eventC06.jpg";
import event07 from "../assets/img/events/eventC07.jpg";
import event08 from "../assets/img/events/eventC08.jpg";
import event09 from "../assets/img/events/eventC09.jpg";
import event10 from "../assets/img/events/eventC10.jpg";
import event11 from "../assets/img/events/eventC11.jpg";
import event12 from "../assets/img/events/eventC12.jpg";



const EventsCompDetail3 = () => {
  return (
    <div className="events d-flex justify-content-center" id="EventDetail3">
      <Container>
        {/* <Row>
            <Col className="my-5 align-items-left">
                <img src={event01} alt="unplash.com" className="w-200" 
                 width="850px"/>
            </Col>                                   
        </Row> */}

        <Row>
         <Col className="text-center mb-5">
            <h1 class="display-4 font-weight-bolder">Pertemuan Ilmiah Tahunan XXVII POGI 2024 di Hotel Harper Kupang, Nusa Tenggara Timur</h1>
            <p class="lead">Pra PIT 18 - 20 Juli 2024 dan PIT 22 - 24 Juli 2024</p>        
         </Col>
        </Row>

        <Row className="row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1 g-4">
            <Col data-aos="fade-right" data-aos-delay="100">
                <img src={event01} alt="unplash.com" className="w-100" 
                 width="850px"/> 
            </Col>
            <Col data-aos="fade-right" data-aos-delay="100">
                <img src={event02} alt="unplash.com" className="w-100" 
                 width="850px"/>              
            </Col>
            <Col data-aos="fade-right" data-aos-delay="100">
                <img src={event03} alt="unplash.com" className="w-100" 
                 width="850px"/> 
            </Col>
            <Col data-aos="fade-right" data-aos-delay="100">
                <img src={event04} alt="unplash.com" className="w-100" 
                 width="850px"/> 
            </Col>
            <Col data-aos="fade-right" data-aos-delay="100">
                <img src={event05} alt="unplash.com" className="w-100" 
                 width="850px"/> 
            </Col>
            <Col data-aos="fade-right" data-aos-delay="100">
                <img src={event06} alt="unplash.com" className="w-100" 
                 width="850px"/> 
            </Col>
            <Col data-aos="fade-right" data-aos-delay="100">
                <img src={event07} alt="unplash.com" className="w-100" 
                 width="850px"/> 
            </Col>
            <Col data-aos="fade-right" data-aos-delay="100">
                <img src={event08} alt="unplash.com" className="w-100" 
                 width="850px"/> 
            </Col>
            <Col data-aos="fade-right" data-aos-delay="100">
                <img src={event09} alt="unplash.com" className="w-100" 
                 width="850px"/> 
            </Col>
            <Col data-aos="fade-right" data-aos-delay="100">
                <img src={event10} alt="unplash.com" className="w-100" 
                 width="850px"/> 
            </Col>
            <Col data-aos="fade-right" data-aos-delay="100">
                <img src={event11} alt="unplash.com" className="w-100" 
                 width="850px"/> 
            </Col>
            <Col data-aos="fade-right" data-aos-delay="100">
                <img src={event12} alt="unplash.com" className="w-100" 
                 width="850px"/> 
            </Col>
      </Row>
      <br/><br/>     
      <Row></Row>
                 

      </Container>

    </div>
 )
}

export default EventsCompDetail3;
