import React from 'react';
import NavProductDesc from "../components/NavProductDesc";
import ProdZepfmayoscissorscurved from '../components/ProdZepfmayoscissorscurved';
import FooterComp from '../components/FooterComp';


const ProductZepfmayoscissorscurved = () => {
  return( 
   <div>
    <NavProductDesc /> 
    {/* <HeroProductComp /> */}
    <ProdZepfmayoscissorscurved />
    <FooterComp />
   </div>
  
  ) 
}

export default ProductZepfmayoscissorscurved;