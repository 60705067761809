import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
    

const CustAreaB = () => {
  return (
    

    <div className="custarea2 d-flex align-items-center">
      <Container>
         <Row>
            <Col>
               {/* <h4 className="text-left fw-bold" data-aos="fade-left" data-aos-delay="600"> Marketing</h4>  */}
               <h4 className="text-left fw-bold">Bogor, Cianjur, Sukabumi</h4> 
               
               <ul class="list-group mx-3 text-black-50">
                  <li>  RS PMI, </li>
                  <li>  RS Azra, </li>
                  <li>  RSU Melania, </li>
                  <li>  RS Marzoeki Mahdi, </li>
                  <li>  RS Bogor Medical Center, </li>
                  <li>  RS Permata Cibubur, </li>
                  <li>  RS Ummi, </li>
                  <li>  RSUD Samsudin, </li>
                  <li>  RSUD Sayang, </li>
                  <li>  RB Pasutri, </li>
                  <li>  RS Permata Jonggol, </li>
                  <li>  RS Mary Cilengsi, </li>
                  <li>  RS Hermina Bogor, </li>
                  <li>  RS Karuni Kasih, </li>
                  <li>  RS Islam Bogor, </li>
                  <li>  RSUD Kota Bogor, </li>
                  <li>  RSIA Pasutri, </li>
                  <li>  RSU Juliana, </li>
                  <li>  RSU Medika Dramaga, </li>
                  <li>  RS Salak, </li>
                  <li>  RSU Ummi, </li>
                  <li>  RSU Vania, </li>
                  <li>  RS Dr. Hafiz, </li>
                  <li>  RS Assifa, </li>
                  <li>  RS Ridogalih, </li>
                  <li>  RS Paragon, </li>
                  <li>  RS Hermina Mekarsari, </li>
                  <li>  RS Citra Insani, </li>
                  <li>  RS FMC, </li>
                  <li>  RS Dompet Duafa </li>
               </ul>
            </Col>
            <Col>
               {/* <h4 className="text-left fw-bold" data-aos="fade-left" data-aos-delay="600"> Marketing</h4>  */}
               <h4 className="text-left fw-bold">Depok</h4> 
               
               <ul class="list-group mx-3 text-black-50">
                  <li>  RS Sentra Medika Cimanggis, </li>
                  <li>  RS Sentra Medika Cibinong, </li>
                  <li>  RS Puri Cinere, </li>
                  <li>  RS Graha Permata Ibu, </li>
                  <li>  RS Hermina Depok, </li>
                  <li>  RS Meilia Cibubur, </li>
                  <li>  RS Permata Depok, </li>
                  <li>  RS Siloam Jantung Diagram, </li>
                  <li>  RSIA Brawijaya Sawangan, </li>
                  <li>  RSIA Bunda Aliyah Depok, </li>
                  <li>  RSU Bunda Margonda, </li>
                  <li>  RSU Citra Arafiq, </li>
                  <li>  RS Mitra Keluarga Depok </li>
               </ul>
            </Col>   
            <Col>
               {/* <h4 className="text-left fw-bold" data-aos="fade-left" data-aos-delay="600"> Marketing</h4>  */}
               <h4 className="text-left fw-bold">Bekasi</h4> 
               
               <ul class="list-group mx-3 text-black-50">
                  <li>  RS Hermina Bekasi, </li>
                  <li>  RS Hermina Galaxi, </li>
                  <li>  RS Siloam Bekasi Timur, </li>
                  <li>  RS Siloam Sentosa, </li>
                  <li>  RSIA Karuni Kasih, </li>
                  <li>  RS Ananda, </li>
                  <li>  RS Anna Pekayon, </li>
                  <li>  RS Mekarsari, </li>
                  <li>  RS Mitra Keluarga Bekasi, </li>
                  <li>  RS Satria Medika, </li>
                  <li>  RS Hermina Grand Wisata, </li>
                  <li>  RS Annisa Cikarang </li>
               </ul>
            </Col>      
        </Row>         
      </Container>
    </div>
  )
}

export default CustAreaB;
