import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from "react-bootstrap/Button";
import gelspon from "../assets/img/principles/gelspon.jpg";

const CardGelspon = () => {
  return (
    <div>
        <Card className="cardprinciples" >
            <CardMedia
            component="img"
            alt="green iguana"
            height="140"
            image={gelspon}
            />
                <CardActions  class="text-center mb-2" >
                <Button 
                className='cardbtn'
                color="primary" 
                onClick={(e) => {
                e.preventDefault();
                window.open('https://eucareindia.com/product/gelspon/', '_blank');                
                // window.location.href='https://eucareindia.com/product/gelspon/';
                }}>
                    
                Learn More
                </Button>
            </CardActions>                     
        </Card>  
    </div>
  )
}

export default CardGelspon;
