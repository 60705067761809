import React from 'react';
import NavProductZepfComp from "../components/NavProductZepfComp";
import NavMainProductComp from "../components/NavMainProductComp";

import HeroProductZepf from "../components/HeroProductZepf";
import ProductDetailZepfComp from '../components/ProductDetailZepfComp';
import FooterComp from '../components/FooterComp';
import NavMainComp from "../components/NavMainComp";

const ProductZepfpage = () => {
  return( 
   <div>
    <NavProductZepfComp /> 
    {/* <NavMainProductComp />  */}
    <HeroProductZepf />
    <ProductDetailZepfComp />
    <FooterComp />
   </div>
  
  ) 
}

export default ProductZepfpage;