import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import gelspon1 from "../assets/img/gelspon/gelspon1.jpg";
import gelspon2 from "../assets/img/gelspon/gelspon2.jpg";
import gelspon3 from "../assets/img/gelspon/gelspon3.jpg";
import { Navbar, Nav} from "react-bootstrap";
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useNavigate } from 'react-router-dom';

const GelsponComp = () => {
  const navigate = useNavigate();

  return (
    <div className="gelsponproduct d-flex justify-content-center">
      <Container>

      <Navbar expand="lg" bg="warning" variant="dark">       
            <Nav className="text-left">
               <Nav.Link href="/#home" className="navtitle mx-2 text-dark" >Home</Nav.Link>
               {/* <h4 className="text-dark"> / </h4> */}

               <NavDropdown 
                    title={
                      <span className="text-dark">Products</span>
                  }
                  >
                    <NavDropdown.Item className="navdropproduct" onClick={() => navigate('/productatramat')}>Atramat</NavDropdown.Item>
                    <NavDropdown.Item className="navdropproduct" onClick={() => navigate('/productzepf')}>Zepf</NavDropdown.Item>
                    <NavDropdown.Item className="navdropproduct" onClick={() => navigate('/productgelspon')}>Gelspon</NavDropdown.Item>
               </NavDropdown>
            </Nav> 
         </Navbar>         
       
         <Row>
               <Col className="align-items-center" data-aos="fade-up" data-aos-delay="100">
                  <h1 className="text-left fw-bold">Gelspon</h1>
               </Col>
         </Row> 

         <Row className="row-cols-lg-2 row-cols-md-2 row-cols-sm-2 row-cols-1 g-2">        
            {/* <Col className="my-2 align-items-left"> */}
            <Col className="my-2 align-items-left py-5 px-3" data-aos="fade-up" data-aos-delay="200">
                <img src={gelspon1} alt="unplash.com" className="w-20" />
            </Col>   
            <Col className="my-2 align-items-left py-5 px-3" data-aos="fade-up" data-aos-delay="200">
                <img src={gelspon2} alt="unplash.com" className="w-20" />
            </Col>          
            {/* <Col className="my-2 align-items-left py-5 px-3" data-aos="fade-up" data-aos-delay="200">
                <img src={gelspon3} alt="unplash.com" className="w-20" />
            </Col>                       */}
         
         </Row>

         <Row>
               {/* <Col className="my-2 align-items-center"> */}
               <Col className="md-2 align-items-center">               
                  <h5 className="text-left text-black-50">Spons Steril Hemostatik berbahan gelatin yang dapat diserap.</h5>
               </Col>
         </Row>   

         <Row>
               {/* <Col className="my-2 align-items-center"> */}
               <Col className="md-2 align-items-center">               
                  <p className="text-left fw-bold">Technical Spesifikasi</p>
               </Col>
         </Row>       


         <Row>
            <Col className="mb-5 align-items-center">
               <ul class="myUL list-group mx-5 text-black-50">
                  <li>  Spons hemostatik bedah yang dibuat dari bahan gelatin yang sangat murni dari sapi 
                        untuk digunakan dalam berbagai prosedur bedah, di mana hemostasis tradisional sulit 
                        atau tidak praktis dan penggunaan bahan yang tidak dapat diserap lainnya tidak diinginkan, </li>
                  <li>  Tersedia dalam berbagai dimensi tertentu dengan kemasan blister steril ganda sudah tersterilisasi 
                        gamma dengan kantong ukuran tertentu atau ukuran khusus. </li>
               </ul>
            </Col>
        </Row>                         


      </Container>     
    </div>
  )
}

export default GelsponComp;
;