import React from 'react';
import NavProductAtramatComp from "../components/NavProductAtramatComp";
import HeroProductAtramat from "../components/HeroProductAtramat";
import ProductDetailAtramatComp from '../components/ProductDetailAtramatComp';
import FooterComp from '../components/FooterComp';
import NavMainComp from "../components/NavMainComp";

const ProductAtramatpage = () => {
  return( 
   <div>
    <NavProductAtramatComp /> 
    {/* <NavMainComp />      */}
    <HeroProductAtramat />
    <ProductDetailAtramatComp />
    <FooterComp />
   </div>
  
  ) 
}

export default ProductAtramatpage;